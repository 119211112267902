import React from "react";
import { WarningCardStyled } from "./card.styled";
import { Box, Image } from "@nubeteck/components";
import { WarningSVG } from "src/assets";

interface IWarningCard {
  size: string;
  title?: string;
  message: string;
}

const WarningCard = ({ size, title, message }: IWarningCard) => {
  return (
    <WarningCardStyled
      style={{
        maxWidth: size === "small" ? "500px" : "auto",
        minHeight: size === "large" ? "290px" : "110px",
      }}
    >
      <Box>
        {size === "large" && <p>{title}</p>}
        <span>{message}</span>
      </Box>
      <Image preview={false} src={WarningSVG} width={180} />
    </WarningCardStyled>
  );
};

export default WarningCard;
