import { CardText, CardTextStrong } from "src/components/reports/cards/report-cards.styled";
import { TotalContainerStyled, TotalFlexStyled } from "src/pages/student-pages/cart/cart.styled";
import { FormatterUtils } from "@nubeteck/utils";
import { Space } from "antd";
import React from "react";

interface ICartMoney {
  cargosMonedas: { colones: boolean; dolares: boolean };
  totalPendiente: { colones: number; dolares: number };
  totalCuotas: number;
  reglaEstimacion: number;
}

function CartMoneyInfo({
  cargosMonedas,
  totalPendiente,
  totalCuotas,
  reglaEstimacion,
}: ICartMoney) {
  return (
    <TotalContainerStyled>
      <Space direction="vertical" style={{ paddingTop: 8 }}>
        {reglaEstimacion && (
          <TotalFlexStyled>
            <CardTextStrong>Regla aplicada</CardTextStrong>
            <CardText>{reglaEstimacion}</CardText>
          </TotalFlexStyled>
        )}
        <TotalFlexStyled>
          <CardTextStrong>Pago al contado</CardTextStrong>
          {cargosMonedas?.colones && (
            <CardText>{`₡ ${FormatterUtils.number(
              `${(totalPendiente.colones - totalCuotas ?? 0).toFixed(2)}`,
            )}`}</CardText>
          )}
          {cargosMonedas?.dolares && (
            <CardText>{`$ ${FormatterUtils.number(
              `${(totalPendiente.dolares - totalCuotas ?? 0).toFixed(2)}`,
            )}`}</CardText>
          )}
        </TotalFlexStyled>
        <TotalFlexStyled>
          <CardTextStrong>Pago en cuotas</CardTextStrong>
          {cargosMonedas?.colones && (
            <CardText>{`₡ ${FormatterUtils.number(`${totalCuotas ?? 0}`)}`}</CardText>
          )}
          {cargosMonedas?.dolares && (
            <CardText>{`$ ${FormatterUtils.number(`${totalCuotas ?? 0}`)}`}</CardText>
          )}
        </TotalFlexStyled>
        <TotalFlexStyled>
          <CardTextStrong>Total materias y servicios</CardTextStrong>
          {cargosMonedas?.colones && (
            <CardText>{`₡ ${FormatterUtils.number(
              `${(totalPendiente.colones ?? 0).toFixed(2)}`,
            )}`}</CardText>
          )}
          {cargosMonedas?.dolares && (
            <CardText>{`$ ${FormatterUtils.number(
              `${(totalPendiente.dolares ?? 0).toFixed(2)}`,
            )}`}</CardText>
          )}
        </TotalFlexStyled>
      </Space>
    </TotalContainerStyled>
  );
}

export default CartMoneyInfo;
