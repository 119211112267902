/* eslint-disable @typescript-eslint/no-explicit-any */

import { apiProfesores } from "./apiBaseQuery";

export const attendanceServices: any = apiProfesores.injectEndpoints({
  endpoints: (builder) => ({
    getAttendanceLogs: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/Attendances/GetLogs/${seccionId}`,
          method: "GET",
        };
      },
    }),
    getAttendanceList: builder.mutation({
      query: (data) => {
        return {
          url: `/Attendances/Get?SeccionId=${data.seccionId}&Fecha=${data.fecha}&Nombre=${data.nombre}`,
          method: "GET",
        };
      },
    }),
    getAttendanceDates: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/Attendances/GetDates/${seccionId}`,
          method: "GET",
        };
      },
    }),
    getAttendanceStatus: builder.mutation({
      query: () => {
        return {
          url: `/AttendanceStatus/Get`,
          method: "GET",
        };
      },
    }),
    saveAttendance: builder.mutation({
      query: (body) => {
        const id = body.seccionId;
        delete body.seccionId;
        return {
          url: `/Attendances/Update/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAttendanceLogsMutation,
  useGetAttendanceListMutation,
  useGetAttendanceDatesMutation,
  useGetAttendanceStatusMutation,
  useSaveAttendanceMutation,
} = attendanceServices;
