/* eslint-disable @typescript-eslint/no-explicit-any */

import { apiProfesores } from "./apiBaseQuery";

export const gradesServices: any = apiProfesores.injectEndpoints({
  endpoints: (builder) => ({
    getGradesLogs: builder.mutation({
      query: (seccionId) => {
        return {
          url: `Grades/GetLogs/${seccionId}`,
          method: "GET",
        };
      },
    }),
    getGradesByActivity: builder.mutation({
      query: (params) => {
        return {
          url: `Grades/GetByActivity?ActividadId=${params.actividadId}&SeccionId=${params.seccionId}`,
          method: "GET",
        };
      },
    }),
    updateGradesByActivity: builder.mutation({
      query: (data) => {
        const id = data.actividadId;
        delete data.actividadId;
        return {
          url: `Grades/UpdateGradeByActivity/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getGradesByStudent: builder.mutation({
      query: (params) => {
        return {
          url: `/Grades/GetByStudent/?EstudianteId=${params.estudianteId}&SeccionId=${params.seccionId}&Actividad=${params.actividad}`,
          method: "GET",
        };
      },
    }),
    updateGradesByStudent: builder.mutation({
      query: (data) => {
        const id = data.estudianteId;
        delete data.estudianteId;
        return {
          url: `/Grades/UpdateGradeByStudent/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getGradesByCourse: builder.mutation({
      query: (params) => {
        return {
          url: `/Grades/GetBySeccion?SeccionId=${params.seccionId}&Nombre=${params.estudianteNombre}&IsScaled=${params.isScaled}`,
          method: "GET",
        };
      },
    }),
    updateGradesByCourse: builder.mutation({
      query: (data) => {
        return {
          url: `/Grades/UpdateGradeBySection`,
          method: "PUT",
          body: data,
        };
      },
    }),
    downloadGradesByCourse: builder.mutation({
      query: (body) => {
        return {
          url: `/Grades/DescargarExcel`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    getAdobeFilePreview: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/Grades/GetReport/${seccionId}`,
          method: "GET",
          responseHandler: "text",
        };
      },
    }),
    getAdobeRedirect: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/Grades/CloseActa/${seccionId}`,
          method: "POST",
          responseHandler: "text",
        };
      },
    }),
  }),
});

export const {
  useGetGradesByStudentMutation,
  useGetGradesByActivityMutation,
  useGetGradesByCourseMutation,
  useUpdateGradesByCourseMutation,
  useUpdateGradesByStudentMutation,
  useUpdateGradesByActivityMutation,
  useDownloadGradesByCourseMutation,
  useGetGradesLogsMutation,
  useGetAdobeFilePreviewMutation,
  useGetAdobeRedirectMutation,
} = gradesServices;
