import React from "react";
import { SmallHeadingStyled } from "../texts/texts.styled";
import { themeColors } from "src/core";

type Action = {
  accion: string;
  estudiante: string;
  actividad: string;
  calificacionAnterior: string;
  calificacionColorAnterior: string;
  calificacionNuevo: string;
  calificacionNuevoColor: string;
};

export interface TimelineGradesData {
  modificadoPor: string;
  dateTime: string;
  actions: Action[];
}

interface TimelineGradesProps {
  data: TimelineGradesData[];
}

const TimelineGradesCustom: React.FC<TimelineGradesProps> = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            marginTop: "20px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <SmallHeadingStyled style={{ padding: "0px 10px 0px 10px" }}>
              {item.dateTime}:
            </SmallHeadingStyled>
            <p style={{ color: themeColors.colorAccent, margin: "0px" }}>
              {item.actions[0].accion} {item.actions[0].estudiante} para la actividad{" "}
              {item.actions[0].actividad}
            </p>
          </div>

          {item.actions.map((action, actionIndex) => (
            <div key={actionIndex}>
              {actionIndex > 0 && (
                <p
                  style={{ color: themeColors.colorAccent, paddingLeft: "10px", marginTop: "0px" }}
                >
                  {action.accion} {action.estudiante} para la actividad {action.actividad}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: themeColors.colorGray,
                    paddingLeft: "10px",
                    margin: "0px",
                    alignContent: "center",
                  }}
                >
                  Calificación:
                </p>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      backgroundColor: "#F2D5D3",
                      height: "30px",
                      width: "auto",
                      padding: "0px 15px 0px 15px",
                      marginLeft: "10px",
                      alignContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                      textDecorationLine: "line-through",
                    }}
                  >
                    {action.calificacionAnterior}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#D3F2D8",
                      height: "30px",
                      width: "auto",
                      padding: "0px 15px 0px 15px",
                      marginLeft: "10px",
                      alignContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                    }}
                  >
                    {action.calificacionNuevo}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h4
              style={{
                padding: "0px 10px 0px 10px",
                color: themeColors.colorTextHeading,
              }}
            >
              Modificado por:
            </h4>
            <p>{item.modificadoPor}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default TimelineGradesCustom;
