import {
  useCreatePeriodVariantMutation,
  useGetPeriodVariantByIdMutation,
  useGetPeriodVariantsMutation,
  useGetRuleTypesMutation,
  useGetRulesMutation,
  useGetRulesVariablesMutation,
  useUpdatePeriodVariantMutation,
  useUpdatePeriodVariantStateMutation,
} from "src/services/selectionDatesServices";
import {
  MediumLargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon } from "@nubeteck/components";
import { Collapse, Input, InputNumber, Layout, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import ConfigurationInput from "../generalSettings/settingsInput";
import { IPeriodosFechas, IReglasVariantes } from "@/Interfaces/datesSettings";
import { ArrayUtils } from "@nubeteck/utils";

const { Content } = Layout;

const PeriodEstimationVariants = ({
  goBack,
  periodo,
}: {
  goBack: () => void;
  periodo: IPeriodosFechas;
}) => {
  const [form] = Form.useForm<IReglasVariantes>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [varianteId, setVarianteId] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalVariablesOpen, setModalvariablesOpen] = useState(false);
  const [variableSelected, setVariableSelected] = useState<string | null>(null);

  const formRef = React.useRef<FormInstance>(null);
  const [getVariants, { data, isLoading }] = useGetPeriodVariantsMutation();
  const [getVariables, { data: variables, isLoading: isLoadingVariables }] =
    useGetRulesVariablesMutation();
  const [getVariantById, { data: variantById }] = useGetPeriodVariantByIdMutation();
  const [getRules, { data: rulesData }] = useGetRulesMutation();
  const [
    createVariant,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate },
  ] = useCreatePeriodVariantMutation();
  const [
    updateVariant,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
  ] = useUpdatePeriodVariantMutation();
  const [
    updateVariantState,
    { isLoading: isLoadingState, isSuccess: isSuccessState, isError: isErrorState },
  ] = useUpdatePeriodVariantStateMutation();
  const [getRuleTypes, { data: ruleTypes, isLoading: isLoadingRuleTypes }] =
    useGetRuleTypesMutation();

  const tiposReglasOptions = ArrayUtils.selectLabelValue(
    ruleTypes?.data ?? [],
    "reglaTipoNombre",
    "reglaTipoId",
  );

  const tipoReglaIdForm = Form.useWatch("reglaTipoId", form);

  useEffect(() => {
    getRuleTypes();
  }, [getRuleTypes]);

  useEffect(() => {
    if (periodo.periodoId !== 0) {
      getRules(periodo.periodoId);
    }
  }, [getRules, periodo]);

  useEffect(() => {
    getVariables();
  }, [getVariables]);

  useEffect(() => {
    getVariants(periodo.procesoId);
  }, [
    getVariants,
    isSuccessCreate,
    isSuccessUpdate,
    isErrorCreate,
    isErrorUpdate,
    periodo,
    isSuccessState,
    isErrorState,
  ]);

  useEffect(() => {
    if (isErrorUpdate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setVarianteId(0);
    }
    setDrawerOpen(false);
  }, [isSuccessUpdate, form, isErrorUpdate]);

  useEffect(() => {
    if (isErrorCreate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setVarianteId(0);
    }
    setDrawerOpen(false);
  }, [isSuccessCreate, form, isErrorCreate]);

  const onClickEditarVariante = React.useCallback(
    (record: IReglasVariantes) => {
      setVarianteId(record.reglaId);
      getVariantById(record.reglaId).then((response: { data: { data: IReglasVariantes } }) => {
        form.setFieldsValue(response?.data?.data);
        setDrawerOpen(true);
      });
    },
    [form, getVariantById],
  );

  const cambiarEstadoVariante = React.useCallback(
    (value: number, id: number) => {
      updateVariantState({ id, value: value === 11 ? false : true });
    },
    [updateVariantState],
  );

  const submit = (values: IReglasVariantes) => {
    if (varianteId !== 0) {
      updateVariant({
        ...values,
        id: varianteId,
      });
    } else {
      createVariant({ ...values, procesoId: periodo.procesoId });
    }
  };

  const handleOkVariables = () => {
    if (variableSelected !== null) {
      const newConditionText = `${form.getFieldValue("condicionCodigo") ?? ""}@${variableSelected}`;
      form.setFieldValue("condicionCodigo", newConditionText);
    }

    setVariableSelected(null);
    setModalvariablesOpen(false);
  };

  const columns = React.useMemo<ColumnsType<IReglasVariantes>>(
    () => [
      {
        title: "Nombre de la regla",
        dataIndex: "nombre",
      },
      {
        title: "Prioridad",
        dataIndex: "prioridad",
      },
      {
        title: "Estado",
        dataIndex: "estadoNombre",
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.reglaId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => onClickEditarVariante(record),
                },
                {
                  key: "2",
                  label: record.estadoId === 11 ? "Inactivar" : "Activar",
                  icon: <Icon name={record.estadoId === 11 ? "" : ""} color="#A7A7A7" />,
                  onClick: () => cambiarEstadoVariante(record.estadoId, record.reglaId),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarVariante, cambiarEstadoVariante],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <SectionHeaderStyled>
          <MediumLargeHeadingStyled>
            Variantes de reglas de estimación para el periodo {periodo.periodoNombre}
          </MediumLargeHeadingStyled>
          <Flex style={{ gap: "15px" }}>
            <Button onClick={goBack}>Ir atrás</Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
                setDrawerOpen(true);
              }}
            >
              Nueva variante
            </Button>
          </Flex>
        </SectionHeaderStyled>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IReglasVariantes>
            loading={isLoading || isLoadingCreate || isLoadingUpdate || isLoadingState}
            columns={columns}
            dataSource={data?.data?.reglas}
            rowKey={(v) => v.reglaId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            size="large"
            placement="right"
            title={
              varianteId !== 0
                ? `Edición de la regla ${variantById?.data?.nombre}`
                : "Nueva variante de reglas de estimación"
            }
            open={drawerOpen}
            onClose={() => {
              setVarianteId(0);
              setDrawerOpen(false);
            }}
          >
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              onFinish={submit}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="nombre"
                label="Nombre de la regla"
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="condicionCodigo"
                label={
                  <Flex style={{ gap: "10px", alignItems: "center" }}>
                    <label htmlFor="condicionCodigo">Condiciones de la regla (SQL)</label>
                    <Button size="small" onClick={() => setModalvariablesOpen(true)}>
                      Insertar variable
                    </Button>
                  </Flex>
                }
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                {drawerOpen && (
                  <ConfigurationInput
                    data={{
                      language: "sql",
                      id: 1,
                      label: "",
                      nombre: "condicionCodigo",
                      tipo: "CodeEditor",
                      valor: form.getFieldValue("condicionCodigo"),
                      descripcion: "Código SQL con las condiciones de la regla de estimación",
                    }}
                    onChange={(value) => form.setFieldValue("condicionCodigo", value ?? null)}
                  />
                )}
              </Form.Item>
              <Form.Item
                name="reglaTipoId"
                label="Tipo de regla"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Seleccionar tipo de regla"
                  size="large"
                  loading={isLoadingRuleTypes}
                  options={tiposReglasOptions}
                />
              </Form.Item>
              <Form.Item
                name="prioridad"
                label="Prioridad de la variante"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <InputNumber min={1} controls={false} />
              </Form.Item>

              <Collapse
                items={[
                  {
                    key: 1,
                    label: (
                      <SmallHeadingStyled style={{ margin: 0 }}>
                        Reglas de estimación
                      </SmallHeadingStyled>
                    ),
                    children: (
                      <div>
                        {" "}
                        <Form.Item
                          name="reglaContado"
                          label="Pago único (colones)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaContadoAmazon"
                          label="Pago único con Amazon (colones)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotas"
                          label="Pago a cuotas (colones)"
                          style={{ marginBottom: 4 }}
                          required={tipoReglaIdForm !== 1}
                          rules={[
                            {
                              required: tipoReglaIdForm !== 1,
                              message: "Este campo es requerido.",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotasAmazon"
                          label="Pago a cuotas con Amazon (colones)"
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaContadoDolares"
                          label="Pago único (dólares)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaContadoAmazonDolares"
                          label="Pago único con Amazon (dólares)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotasDolares"
                          label="Pago a cuotas (dólares)"
                          style={{ marginBottom: 4 }}
                          required={tipoReglaIdForm !== 1}
                          rules={[
                            {
                              required: tipoReglaIdForm !== 1,
                              message: "Este campo es requerido.",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotasAmazonDolares"
                          label="Pago a cuotas con Amazon (dólares)"
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar regla"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                      </div>
                    ),
                  },
                ]}
                defaultActiveKey={1}
                ghost
                style={{ background: "transparent" }}
              />

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingUpdate || isLoadingCreate}
              >
                Guardar
              </Button>
            </Form>
            <Modal
              closable={false}
              open={modalVariablesOpen}
              onOk={handleOkVariables}
              okText="Insertar"
              onCancel={() => setModalvariablesOpen(false)}
              cancelText="Cancelar"
              style={{ maxWidth: 450 }}
            >
              <Form.Item label="Variable a insertar">
                <Select
                  loading={isLoadingVariables}
                  onChange={(e) => setVariableSelected(e)}
                  options={variables?.data?.map((va: string) => ({
                    label: va,
                    value: va,
                  }))}
                  allowClear
                  value={variableSelected ?? ""}
                  placeholder="Seleccione una variable"
                />
              </Form.Item>
            </Modal>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default PeriodEstimationVariants;
