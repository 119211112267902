import React, { useEffect } from "react";
import { Image } from "@nubeteck/components";
import { LogoCenfotecPNG } from "src/assets";
import { LoginSVG } from "src/assets";
import {
  FlexContainerStyled,
  ImageContainerStyled,
  FormSection,
  SubHeadingStyled,
  Frame,
  LoginButtonContainer,
} from "./login.styled";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useLoginUserMutation } from "src/services";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import toast from "react-hot-toast";
import { toastErrorStyle } from "src/constants";

const Login = () => {
  const [loginUser, { data, isLoading, isSuccess, error }] = useLoginUserMutation();

  const navigate = useNavigate();
  const handleCallbackResponse = async (response: CredentialResponse) => {
    loginUser({ credential: response.credential });
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("token", data.data.admin ?? data.data.profesor ?? data.data.estudiante);
      let profiles = {};
      if (data.data.admin) profiles = { ...profiles, Admin: data.data.admin };
      if (data.data.estudiante) profiles = { ...profiles, Estudiante: data.data.estudiante };
      if (data.data.profesor) profiles = { ...profiles, Profesor: data.data.profesor };

      localStorage.setItem("profiles", JSON.stringify(profiles));

      localStorage.setItem("refreshToken", JSON.stringify(data.data.refreshToken));
      navigate("/");
    }
    if (error) {
      if ("status" in error) {
        const errStatus = "error" in error ? error.status : error.status;
        if (errStatus == 401) toast.error("Usuario no autorizado.", toastErrorStyle);
        else
          toast.error("Ha ocurrido un error iniciando sesión, Intente de nuevo.", toastErrorStyle);
      }
    }
  }, [isSuccess, data, navigate, error]);

  return (
    <Spin spinning={isLoading} size="large">
      <FlexContainerStyled style={{ justifyContent: "space-between" }}>
        <Frame>
          <FormSection>
            <Image src={LogoCenfotecPNG} width={145} preview={false} />
            <SubHeadingStyled>Autoservicio</SubHeadingStyled>
            <LoginButtonContainer>
              <SubHeadingStyled>Inicio de sesión</SubHeadingStyled>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleCallbackResponse(credentialResponse);
                }}
                onError={() => {
                  toast.error("Ha ocurrido un error", toastErrorStyle);
                }}
              />
            </LoginButtonContainer>
          </FormSection>
        </Frame>

        <ImageContainerStyled>
          <Image src={LoginSVG} width={250} preview={false} />
        </ImageContainerStyled>
      </FlexContainerStyled>
    </Spin>
  );
};

export default Login;
