import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import { Button } from "@nubeteck/components";
import { DatePicker, Layout, Select, Tabs, TabsProps } from "antd";
import React from "react";
import CareersImport from "./careersImport";
import SubjectsImport from "./subjectsImport";
import StudentsImport from "./studentsImport";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useGetCareersImportsMutation,
  useGetCoursesProjectionsImportsMutation,
  useGetProjectionsImportsMutation,
  useGetStudentsImportsMutation,
  useGetSubjectsImportsMutation,
  useImportDataMutation,
} from "src/services/importsService";
import { useGetAdminPeriodsQuery } from "src/services";
import { ArrayUtils } from "@nubeteck/utils";
import ProjectionsImport from "./projectionsImport";
import CoursesProjectionsImport from "./coursesProjectionsImport";
import useDownloadFile from "src/hooks/useDownloadFile";
import { DateSettings } from "../datesSettings";
import { useMediaQuery } from "src/hooks";
import { ColumnContainer } from "src/components/layout/main-layout/main-layout.styled";

const { Content } = Layout;

const DataImports: React.FC = () => {
  const [getData, { isLoading, isSuccess, error }] = useImportDataMutation();
  const [periodoId, setPeriodoId] = React.useState<number>();
  const [anioToImport, setAnioToImport] = React.useState<number | undefined>(0);
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const { data: periodos } = useGetAdminPeriodsQuery(anioToImport);
  const { data: periodosParaFiltro } = useGetAdminPeriodsQuery(0);
  const [getCareers, { isLoading: isLoadingCareers, data: dataCareers }] =
    useGetCareersImportsMutation();
  const [getStudents, { isLoading: isLoadingStudents, data: dataStudents }] =
    useGetStudentsImportsMutation();
  const [getSubjects, { isLoading: isLoadingSubjects, data: dataSubjects }] =
    useGetSubjectsImportsMutation();
  const [getProjections, { isLoading: isLoadingProjections, data: dataProjections }] =
    useGetProjectionsImportsMutation();
  const [
    getCoursesProjections,
    { isLoading: isLoadingCoursesProjections, data: dataCoursesProjections },
  ] = useGetCoursesProjectionsImportsMutation();

  const { downloadFile, loadingDownload } = useDownloadFile();

  React.useEffect(() => {
    if (error) toast.error(error?.data?.message, toastErrorStyle);
    if (isSuccess) {
      toast.success("Datos importados correctamente", toastSuccessStyle);
    }
  }, [error, isSuccess]);

  const periodosOptions = ArrayUtils.selectLabelValue(periodos?.data ?? [], "nombre", "idPeriodo");
  const filterPeriodosOptions = ArrayUtils.selectLabelValue(
    periodosParaFiltro?.data ?? [],
    "nombre",
    "idPeriodo",
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Carreras`,
      children: (
        <CareersImport
          isLoading={isLoading || isLoadingCareers}
          getData={getCareers}
          data={dataCareers}
        />
      ),
    },
    {
      key: "2",
      label: `Periodos de selección`,
      children: <DateSettings />,
    },
    {
      key: "3",
      label: `Asignaturas`,
      children: (
        <SubjectsImport
          isLoading={isLoading || isLoadingSubjects}
          getData={getSubjects}
          data={dataSubjects}
        />
      ),
    },
    {
      key: "4",
      label: `Estudiantes`,
      children: (
        <StudentsImport
          isLoading={isLoading || isLoadingStudents}
          getData={getStudents}
          data={dataStudents}
        />
      ),
    },
    {
      key: "5",
      label: `Proyección de estudiantes`,
      children: (
        <ProjectionsImport
          isLoading={isLoading || isLoadingProjections}
          getData={getProjections}
          data={dataProjections}
          periodosOptions={filterPeriodosOptions}
          getExcel={(anio: number, periodoId: number) =>
            downloadFile(
              `${process.env.REACT_APP_BASEURL}/Admins/ProyeccionesExcel/`,
              "Proyecciones de estudiantes",
              "POST",
              {
                anio,
                periodoId,
              },
            )
          }
          isLoadingExcel={loadingDownload}
        />
      ),
    },
    {
      key: "6",
      label: `Proyección de cursos`,
      children: (
        <CoursesProjectionsImport
          isLoading={isLoading || isLoadingCoursesProjections}
          getData={getCoursesProjections}
          data={dataCoursesProjections}
          periodosOptions={filterPeriodosOptions}
          getExcel={(anio: number, periodoId: number) =>
            downloadFile(
              `${process.env.REACT_APP_BASEURL}/Admins/ProyeccionesAsignaturasExcel/`,
              "Proyecciones de cursos",
              "POST",
              {
                anio,
                periodoId,
              },
            )
          }
          isLoadingExcel={loadingDownload}
        />
      ),
    },
  ];

  const importData = () => {
    getData(periodoId);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <ColumnContainer>
      <SectionHeaderStyled style={{ height: !isMobile ? "70px" : "auto" }}>
        <LargeHeadingStyled>Importación de datos</LargeHeadingStyled>
        <SectionHeaderStyled style={{ gap: "15px", justifyContent: "right" }}>
          <label htmlFor="anio">Importar datos por año y periodo</label>
          <DatePicker
            id="anio"
            picker="year"
            onChange={(e) => {
              setAnioToImport(e?.toDate().getFullYear());
            }}
            placeholder="Año"
          />

          <Select
            options={periodosOptions}
            filterOption={filterOption}
            placeholder="Seleccione un periodo"
            showSearch
            optionFilterProp="children"
            allowClear
            onChange={(e) => {
              setPeriodoId(e);
            }}
          />

          <Button type="primary" onClick={importData} disabled={!periodoId || isLoading}>
            Importar datos
          </Button>
        </SectionHeaderStyled>
      </SectionHeaderStyled>

      <Content style={{ marginTop: "16px" }}>
        <Tabs
          tabBarStyle={{
            justifyContent: "space-between",
            width: "100%",
          }}
          defaultActiveKey="month"
          items={items}
        />
      </Content>
    </ColumnContainer>
  );
};

export default DataImports;
