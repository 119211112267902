import React, { MutableRefObject, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Flex } from "@nubeteck/components";
import { Header, MobileMenu } from "../../layout";
import { MainContainer } from "./main-layout.styled";
import { useGetUserInfoMutation } from "src/services";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/core/store/store";
import { setUserInfo, setIsATeacher, setIsAdmin } from "src/core/store/slices/userSlice";
import {
  decodeJwt,
  getIsUserImpersonated,
  getRolesByToken,
  getRolesByTokenImpersonated,
} from "src/utils";
import { Alert, Button } from "antd";
import { useMediaQuery } from "src/hooks";
import { IMenuItem } from "src/Interfaces/options";
import { adminMenuItems, studentMenuItems, teacherMenuItems } from "src/constants";

const MainLayout = () => {
  const [isMenuMobileVisible, setMenuMobileVisible] = React.useState(false);
  const [getUserInfo, { data: userInfo }] = useGetUserInfoMutation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 1180px)");

  const matriculaAdministrativa = localStorage.getItem("matriculaAdministrativa") === "true";
  const token = localStorage.getItem("token");
  const roles = matriculaAdministrativa
    ? getRolesByTokenImpersonated(token ?? "")
    : getRolesByToken(token ?? "");
  const isAdmin =
    roles.includes("administrador(a) del sistema") ||
    roles.includes("cajero") ||
    roles.includes("vicerrectoria") ||
    roles.includes("matricula administrativa");

  const isATeacher = roles.includes("profesor");

  const userImpersonated: MutableRefObject<any> = useRef(null);

  useEffect(() => {
    dispatch(setIsATeacher(isATeacher));
    dispatch(setIsAdmin(isAdmin));
  }, [dispatch, isATeacher, isAdmin]);

  useEffect(() => {
    getUserInfo("");
  }, [getUserInfo]);
  useEffect(() => {
    if (userInfo?.data) {
      dispatch(setUserInfo(userInfo?.data));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    const tokenT = localStorage.getItem("token");
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    userImpersonated.current = getIsUserImpersonated(tokenT ?? "");
  }, [navigate]);

  const getUserName = () => {
    return matriculaAdministrativa && userImpersonated.current
      ? JSON.parse(decodeJwt(userImpersonated.current)).name
      : userInfo?.data?.fullName;
  };

  const getMenuByAdminRoles = (studentMenuItems: IMenuItem[]) => {
    const result = studentMenuItems.filter(
      (menu) => menu.roles?.find((rol) => roles.includes(rol)),
    );
    return result;
  };

  const logoutImpersonated = () => {
    localStorage.setItem("token", userImpersonated.current);
    localStorage.setItem("matriculaAdministrativa", "false");
    localStorage.setItem("refreshToken", JSON.stringify(userImpersonated.current.refreshToken));
    getUserInfo("");
    navigate("/");
    window.location.reload();
  };

  const logout = () => {
    localStorage.setItem("matriculaAdministrativa", "false");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  return (
    <Box>
      <MobileMenu
        isFakeSelecting={matriculaAdministrativa}
        logoutImpersonated={logoutImpersonated}
        menuItems={
          isATeacher
            ? teacherMenuItems
            : isAdmin
            ? getMenuByAdminRoles(adminMenuItems)
            : studentMenuItems
        }
        userName={getUserName()}
        logout={logout}
        isHelpVisible={!isAdmin}
        isVisible={isMenuMobileVisible}
        hide={() => {
          setMenuMobileVisible(false);
        }}
      />
      <Header
        isFakeSelecting={matriculaAdministrativa}
        logoutImpersonated={logoutImpersonated}
        menuItems={
          isATeacher
            ? teacherMenuItems
            : isAdmin
            ? getMenuByAdminRoles(adminMenuItems)
            : studentMenuItems
        }
        userName={getUserName()}
        isHelpVisible={!isAdmin}
        logout={logout}
        openMenu={() => {
          setMenuMobileVisible(true);
        }}
      />
      <MainContainer
        style={{ paddingTop: isMobile && userImpersonated.current ? "250px" : "auto" }}
      >
        {userImpersonated.current ? (
          <>
            <br />
            <Flex
              style={{
                display: "flex",
                alignItems: isMobile ? "start" : "center",
                gap: isMobile ? "7px" : "15px",
                flexDirection: isMobile ? "column" : "row",
                backgroundColor: "#c9e1ff",
                position: "absolute",
                top: isMobile ? "80px" : "63px",
                left: 0,
                width: "100%",
                justifyContent: "center",
                padding: isMobile ? "10px" : "0px",
              }}
            >
              <Alert
                message={
                  matriculaAdministrativa
                    ? `Matrícula del estudiante ${userInfo?.data.fullName} - ${userInfo?.data.id}`
                    : `El usuario del estudiante ${userInfo?.data.fullName} - ${userInfo?.data
                        .id} está siendo utilizado por ${JSON.parse(
                        decodeJwt(userImpersonated.current),
                      )?.name}`
                }
                type="info"
                style={{ backgroundColor: "transparent", border: "none" }}
                showIcon
              />
              <Button onClick={logoutImpersonated}>
                {matriculaAdministrativa ? "Salir" : "Volver a tu usuario"}
              </Button>
            </Flex>
          </>
        ) : // </Tooltip>
        null}
        <Outlet />
      </MainContainer>
    </Box>
  );
};

export default MainLayout;
