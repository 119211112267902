import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon, Select } from "@nubeteck/components";
import { ArrayUtils } from "@nubeteck/utils";
import { Input, Layout, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { useGetRolesQuery } from "src/services";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useChangeUserStateMutation,
  useCreateUserMutation,
  useGetUsersMutation,
  useUpdateUserMutation,
} from "src/services/userServices";

const { Content } = Layout;

interface IUser {
  userId: number;
  isActive: true;
  fullName: string;
  username: string;
  email: string;
  roles: number[];
  rolesString?: string;
}

const Users: React.FC = () => {
  const [form] = Form.useForm<IUser>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usuarioId, setUsuarioId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [userFiltro, setUserFiltro] = React.useState<string>("");
  const formRef = React.useRef<FormInstance>(null);

  const { data: roles } = useGetRolesQuery("");
  const [getUsers, { data, isLoading }] = useGetUsersMutation();
  const [
    createUser,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate },
  ] = useCreateUserMutation();
  const [
    updateDate,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
  ] = useUpdateUserMutation();
  const [
    changeUserState,
    {
      isLoading: isLoadingState,
      isSuccess: isSuccessState,
      isError: isErrorState,
      error: errorState,
    },
  ] = useChangeUserStateMutation();

  React.useEffect(() => {
    getUsers(userFiltro);
  }, [
    getUsers,
    isSuccessCreate,
    isSuccessUpdate,
    isErrorCreate,
    isErrorUpdate,
    isSuccessState,
    userFiltro,
  ]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (isErrorCreate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setUsuarioId(0);
    }
  }, [isSuccessCreate, form, isErrorCreate]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (isErrorUpdate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setUsuarioId(0);
    }
  }, [isSuccessUpdate, form, isErrorUpdate]);

  React.useEffect(() => {
    if (isErrorState) {
      toast.error("Ha ocurrido un error actualizando el estado del usuario.", toastErrorStyle);
    }

    if (isSuccessState) {
      toast.success("Datos actualizados correctamente.", toastSuccessStyle);
    }
  }, [errorState, isSuccessState, isErrorState]);

  const onClickEditarUsuario = React.useCallback(
    (record: IUser) => {
      setUsuarioId(record.userId);
      form.setFieldsValue({
        ...record,
      });
      setDrawerOpen(true);
    },
    [form],
  );

  const cambiarEstadoUsuario = React.useCallback(
    (value: boolean, id: number) => {
      changeUserState({ isActive: value, id });
    },
    [changeUserState],
  );

  const submit = (values: any) => {
    const v = {
      ...values,
    };
    if (usuarioId !== 0) {
      updateDate({
        ...v,
        id: usuarioId,
      });
    } else {
      createUser(v);
    }
  };

  const rolesOptions = ArrayUtils.selectLabelValue(roles?.data ?? [], "rolName", "rolId");

  const columns = React.useMemo<ColumnsType<IUser>>(
    () => [
      {
        title: "Usuario",
        dataIndex: "username",
      },
      {
        title: "Nombre",
        dataIndex: "fullName",
      },
      {
        title: "Correo electrónico",
        dataIndex: "email",
      },
      {
        title: "Roles",
        dataIndex: "rolesString",
      },
      {
        title: "Estado",
        dataIndex: "isActive",
        render: (_, record) => (record.isActive ? "Activo" : "Inactivo"),
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.userId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => onClickEditarUsuario(record),
                },
                {
                  key: "2",
                  label: record.isActive ? "Inactivar" : "Activar",
                  icon: <Icon name={record.isActive ? "" : ""} color="#A7A7A7" />,
                  onClick: () => cambiarEstadoUsuario(!record.isActive, record.userId),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarUsuario, cambiarEstadoUsuario],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <LargeHeadingStyled>Usuarios</LargeHeadingStyled>
          <Flex style={{ gap: "15px" }}>
            <Form.Item label="Búsqueda por nombre">
              <Input
                type="text"
                size="small"
                defaultValue={userFiltro}
                onChange={(e) => setUserFiltro(e.target.value)}
                placeholder="Nombre"
                style={{ height: "35px", marginRight: "15px" }}
              />
            </Form.Item>
            <Button
              title=""
              type="primary"
              onClick={() => {
                setDrawerOpen(true);
                form.resetFields();
              }}
            >
              Nuevo usuario
            </Button>
          </Flex>
        </Flex>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IUser>
            loading={isLoading || isLoadingCreate || isLoadingUpdate || isLoadingState}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.userId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title={usuarioId !== 0 ? "Edición de usuario" : "Nuevo usuario"}
            open={drawerOpen}
            onClose={() => {
              setUsuarioId(0);
              setDrawerOpen(false);
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              onFinish={submit}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="fullName"
                label="Nombre"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Correo electrónico"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                name="roles"
                label="Roles"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Select
                  allowClear
                  size="large"
                  options={rolesOptions}
                  mode="multiple"
                  placeholder="Roles del usuario"
                />
              </Form.Item>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingCreate || isLoadingState || isLoadingUpdate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default Users;
