import { createSlice } from "@reduxjs/toolkit";
interface ISelectionInfo {
  subjectCodeForChange: string;
}

const initialState: ISelectionInfo = {
  subjectCodeForChange: "",
};

export const subjectSelectionSlice = createSlice({
  name: "subjectSelection",
  initialState,
  reducers: {
    setSubjectCodeForChange: (state, action) => {
      state.subjectCodeForChange = action.payload;
    },
  },
});

export const { setSubjectCodeForChange } = subjectSelectionSlice.actions;

export default subjectSelectionSlice.reducer;
