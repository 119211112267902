/* eslint-disable @typescript-eslint/no-explicit-any */

import { api } from "./apiBaseQuery";

export const requestsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getRequestTypes: builder.query({
      query: () => {
        return {
          url: `/Solicitudes/SolicitudTipo`,
        };
      },
    }),
    getDataSources: builder.query({
      query: () => {
        return {
          url: `/Solicitudes/FuentesDatos`,
        };
      },
    }),
    getFieldTypes: builder.query({
      query: () => {
        return {
          url: `/Solicitudes/TiposCampos`,
        };
      },
    }),
    getRequestsAdmin: builder.mutation({
      query: () => {
        return {
          url: `/Solicitudes`,
          method: "GET",
        };
      },
    }),
    getRequestByIdAdmin: builder.mutation({
      query: (id: number) => {
        return {
          url: `/Solicitudes/Admin/${id}`,
          method: "GET",
        };
      },
    }),
    getRequestById: builder.mutation({
      query: (id: number) => {
        return {
          url: `/Solicitudes/${id}`,
          method: "GET",
        };
      },
    }),
    createRequestAdmin: builder.mutation({
      query: (body) => {
        return {
          url: `/Solicitudes`,
          method: "POST",
          body,
        };
      },
    }),
    updateRequestAdmin: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Solicitudes/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    updateRequestStateAdmin: builder.mutation({
      query: (id: number) => {
        return {
          url: `/Solicitudes/${id}`,
          method: "DELETE",
        };
      },
    }),
    getRequests: builder.mutation({
      query: (body) => {
        return {
          url: `Solicitudes/Estudiante/Obtener`,
          method: "POST",
          body,
        };
      },
    }),
    createRequest: builder.mutation({
      query: (body) => {
        return {
          url: `Solicitudes/Estudiante`,
          method: "POST",
          body,
        };
      },
    }),
    updateRequestState: builder.mutation({
      query: (id) => {
        return {
          url: `Solicitudes/Estudiante/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetRequestsMutation,
  useGetRequestByIdMutation,
  useGetRequestByIdAdminMutation,
  useCreateRequestMutation,
  useGetRequestConceptsQuery,
  useGetRequestsAdminMutation,
  useCreateRequestAdminMutation,
  useUpdateRequestAdminMutation,
  useUpdateRequestStateAdminMutation,
  useUpdateRequestStateMutation,
  useGetFieldTypesQuery,
  useGetDataSourcesQuery,
  useGetRequestTypesQuery,
} = requestsServices;
