import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon, Select } from "@nubeteck/components";
import { ArrayUtils } from "@nubeteck/utils";
import { Checkbox, Input, Layout, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { useGetTemplateTypesQuery } from "src/services";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useChangeEmailTemplateStateMutation,
  useCreateEmailTemplateMutation,
  useGetEmailTemplatesMutation,
  useUpdateEmailTemplateMutation,
} from "src/services";
import ConfigurationInput from "../generalSettings/settingsInput";

const { Content } = Layout;

interface IEmailTemplate {
  plantillaId: number;
  tipoPlantillaId: number;
  estadoId: number;
  plantillaNombre: string;
  asunto: string;
  cuerpo: any;
  esTextoPlano: boolean;
}

const EmailTemplates: React.FC = () => {
  const [form] = Form.useForm<IEmailTemplate>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [plantillaId, setPlantillaId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [tipoPlantilla, setTipoPlantilla] = React.useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const formRef = React.useRef<FormInstance>(null);
  const { resetFields, getFieldValue, setFieldsValue, setFieldValue, validateFields } = form;

  const { data: tiposPlantillas } = useGetTemplateTypesQuery("");
  const [getEmailTemplates, { data, isLoading }] = useGetEmailTemplatesMutation();
  const [
    createEmailTemplate,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, error: errorCreate },
  ] = useCreateEmailTemplateMutation();
  const [
    updateTemplate,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate },
  ] = useUpdateEmailTemplateMutation();
  const [
    changeEmailTemplateState,
    { isLoading: isLoadingState, isSuccess: isSuccessState, error: errorState },
  ] = useChangeEmailTemplateStateMutation();

  React.useEffect(() => {
    getEmailTemplates();
  }, [
    getEmailTemplates,
    isSuccessCreate,
    isSuccessUpdate,
    errorCreate,
    errorUpdate,
    isSuccessState,
  ]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (errorCreate) toast.error(`Error: ${errorCreate.data?.message}`, toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      resetFields();
      setPlantillaId(0);
    }
  }, [isSuccessCreate, resetFields, errorCreate]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (errorUpdate) toast.error(`Error: ${errorUpdate.data?.message}`, toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      resetFields();
      setPlantillaId(0);
    }
  }, [isSuccessUpdate, resetFields, errorUpdate]);

  React.useEffect(() => {
    if (errorState) {
      toast.error(`Error: ${errorState.data?.message}`, toastErrorStyle);
    }

    if (isSuccessState) {
      toast.success("Plantilla removida correctamente.", toastSuccessStyle);
    }
  }, [errorState, isSuccessState]);

  const onClickEditarPlantilla = React.useCallback(
    (record: IEmailTemplate) => {
      setPlantillaId(record.plantillaId);
      setFieldsValue(record);
      setFieldValue("cuerpo", record.cuerpo);
      setTipoPlantilla(record.tipoPlantillaId);

      setDrawerOpen(true);
    },
    [setFieldsValue, setFieldValue],
  );

  const cambiarEstadoPlantilla = React.useCallback(
    (id: number) => {
      modal.confirm({
        title: `¿Esta seguro(a) de que desea remover esta plantilla?`,
        icon: <Icon name="" color="#FFC53D" />,
        okText: "Remover plantilla",
        onOk: () => {
          changeEmailTemplateState(id);
        },
        cancelText: "Cancelar",
        style: { top: 200 },
      });
    },
    [changeEmailTemplateState, modal],
  );

  const submit = () => {
    validateFields(["plantillaNombre", "tipoPlantillaId", "asunto", "cuerpo", "esTextoPlano"])
      .then((values) => {
        const v = {
          ...values,
        };
        if (plantillaId !== 0) {
          updateTemplate({
            ...v,
            id: plantillaId,
          });
        } else {
          createEmailTemplate(v);
        }
      })
      .catch(() => {
        toast.error(`Algunos campos necesitan ser revisados.`);
      });
  };

  const tiposPlantillasOptions = ArrayUtils.selectLabelValue(
    tiposPlantillas?.data ?? [],
    "tipoPlantillaNombre",
    "tipoPlantillaId",
  );

  const columns = React.useMemo<ColumnsType<IEmailTemplate>>(
    () => [
      {
        title: "Plantilla",
        dataIndex: "plantillaNombre",
      },
      {
        title: "Tipo",
        dataIndex: "tipoPlantillaId",
        render: (e) =>
          tiposPlantillas?.data?.find((tipo: any) => tipo.tipoPlantillaId == e)
            ?.tipoPlantillaNombre || "",
      },
      {
        title: "Asunto",
        dataIndex: "asunto",
      },
      {
        title: "Estado",
        dataIndex: "estadoId",
        render: (_, record) => (record.estadoId == 11 ? "Activo" : "Inactivo"),
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.plantillaId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => onClickEditarPlantilla(record),
                },
                {
                  key: "2",
                  label: "Remover",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => cambiarEstadoPlantilla(record.plantillaId),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarPlantilla, cambiarEstadoPlantilla, tiposPlantillas],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <LargeHeadingStyled>Plantillas</LargeHeadingStyled>

          <Button
            title=""
            type="primary"
            loading={false}
            onClick={() => {
              setDrawerOpen(true);
              resetFields();
            }}
          >
            Nueva plantilla
          </Button>
        </Flex>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IEmailTemplate>
            loading={isLoading || isLoadingCreate || isLoadingUpdate || isLoadingState}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.plantillaId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title={plantillaId !== 0 ? "Edición de plantilla" : "Nueva plantilla"}
            open={drawerOpen}
            onClose={() => {
              setPlantillaId(0);
              setDrawerOpen(false);
              resetFields();
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="plantillaNombre"
                label="Nombre de la plantilla"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="tipoPlantillaId"
                label="Tipo de plantilla"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Select
                  size="large"
                  options={tiposPlantillasOptions}
                  placeholder="Tipo"
                  allowClear
                  onChange={(e) => setTipoPlantilla(e)}
                />
              </Form.Item>
              <Form.Item
                name="asunto"
                label="Asunto"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="esTextoPlano" valuePropName="checked" hasFeedback>
                <Checkbox>Es texto plano</Checkbox>
              </Form.Item>

              <Form.Item rules={[{ required: true }]} label="Cuerpo" name="cuerpo">
                <ConfigurationInput
                  data={{
                    id: 1,
                    label: "Cuerpo",
                    nombre: "cuerpo",
                    tipo: "Editor",
                    useVariables: true,
                    variables:
                      tiposPlantillas?.data?.find(
                        (tipo: any) => tipo.tipoPlantillaId == tipoPlantilla,
                      )?.variables ?? {},

                    valor: getFieldValue("cuerpo"),
                    descripcion: "",
                  }}
                  onChange={(value) => form.setFieldValue("cuerpo", value)}
                />
              </Form.Item>
              <Button
                onClick={() => submit()}
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingCreate || isLoadingState || isLoadingUpdate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
      {contextHolder}
    </Flex>
  );
};

export default EmailTemplates;
