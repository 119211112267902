import { CardStyled } from "src/components/custom/cards/card.styled";
import { Flex, Image, Constants, Text, Box } from "@nubeteck/components";
import { FramePatternPNG as pattern } from "src/assets";
import styled from "styled-components";
// import { devices } from "src/constants";

const { device, maxDevice } = Constants;

export const WelcomeCardStyled = styled(CardStyled)`
  padding: 0;
  flex: 2;
  max-width: 550px;

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    height: 100%;
    gap: 1px;
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    max-width: 100%;
  }
`;

export const DashboardContainer = styled(Flex)`
  max-width: 1440px;
  flex-direction: column;
  gap: 20px;

  @media ${device.mobileS} and ${maxDevice.laptop} {
    max-width: 90vw;
  }
`;

export const DashboardRow = styled(Flex)`
  flex-direction: column;
  gap: 20px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const DashboardImage = styled(Image)`
  min-width: 100px;
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const AccionCardStyled = styled(CardStyled)`
  flex: 1;
  position: relative;
  min-height: 290px;

  .ant-card-body {
    display: flex;
    min-height: inherit;
    justify-content: space-between;

    .ant-image {
      min-height: 80px;
      img {
        position: absolute;
        bottom: 15px;
      }
    }
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    .ant-card-body {
      h3 {
        font-size: 22px;
      }
    }
  }
`;

export const UserCardStyled = styled(CardStyled)`
  padding: 0;

  @media ${device.mobileS} and ${maxDevice.laptop} {
    min-width: 320px;
  }
`;

export const UserCardInfoContainer = styled(Flex)`
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${maxDevice.tablet} {
    flex-direction: column-reverse;
    img{
      display:none;
    }
`;

export const UserInfoLabel = styled(Text)`
  color: #92959e !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: normal !important;

  @media ${device.tablet} and ${maxDevice.laptop} {
    font-size: 22px !important;
  }
`;

export const UserInfoText = styled(Text)`
  color: #15192c;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  word-break: keep-all;

  @media ${device.tablet && maxDevice.laptop} {
    font-size: 20px !important;
  }s
`;

export const UserCardInfo = styled(Flex)`
  flex: 1;
  padding: 32px;
  flex-direction: column;
  gap: 20px;
`;

export const UserInfoContainerStyled = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary100};
  border-radius: 20px;
  padding: 8px;
  align-items: center;
  justify-content: center;

  text-align: center;
  gap: 10px;
  flex-direction: column;

  @media ${device.laptop} {
    max-width: 130px;
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    min-width: 300px;
  }
`;

export const UserInfoTextBlue = styled(Text)`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-size: 13.5px;
  font-weight: 700;
  line-height: normal;

  @media ${device.mobileS && maxDevice.tablet} {
    font-size: 18px;
  }
  @media ${device.tablet && maxDevice.laptop} {
    font-size: 22px;
    max-width: 180px;
  }
`;

export const WelcomeDescText = styled(Text)`
  color: ${({ theme }) => theme.colors.colorText};
  font-size: 18px;
  font-weight: 600;

  @media ${device.mobileS} and ${maxDevice.tablet} {
    font-size: 16px !important;
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    font-size: 20px !important;
  }
`;

export const CurrentSubjectsContainer = styled(Flex)`
  flex: 2;
  flex-direction: column;
`;

export const ScrollViewStyled = styled(Box)`
  height: 250px;
  overflow-y: auto;

  @media ${device.mobileS} and ${maxDevice.laptop} {
    height: 300px;
  }
`;

export const SubjectTitleStyled = styled.h3`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;

  @media ${device.tablet} and ${maxDevice.laptop} {
    font-size: 23px;
    margin-top: 0px;
  }
`;

export const SubjectResumeStyled = styled.div`
  background-image: url(${pattern});
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  justify-content: end;
  margin-bottom: 23px;
`;
export const SubjectInfoContainerStyled = styled.div`
  background-color: white;
  width: 90%;
  border-radius: 20px;
  height: 60px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.09));
  padding: 20px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: var(--black, #373b4e);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    margin: 0px;
    font-weight: 700;
    line-height: 20px;
  }

  span {
    color: var(--black, #373b4e);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    height: 75px;
  }

  @media ${device.tablet} and ${maxDevice.laptop} {
    h4 {
      font-size: 20px;
      margin-bottom: 5px;
    }

    span {
      font-size: 17px;
    }
  }
`;
