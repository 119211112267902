import styled from "styled-components";
import { Constants } from "@nubeteck/components";
import { Flex } from "antd";

const { device, maxDevice } = Constants;

export const MainContainer = styled.main`
  background: ${({ theme }) => theme.colors.colorBgBase};
  display: flex;
  justify-content: center;
  padding: 120px 85px 35px;
  min-height: 95vh;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.primary300};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary500};
    border-radius: 10px;
  }

  .ant-table {
    scrollbar-color: auto;
    .ant-table-body::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    .ant-table-body::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.primary300};
    }
    .ant-table-body::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.primary500};
      border-radius: 10px;
    }
  }

  @media ${maxDevice.mobileL} {
    padding: 90px 20px 30px;
  }

  @media ${device.mobileL && maxDevice.laptop} {
    padding: 145px 7% 40px;
  }

  @media ${device.laptop} {
    padding-left: 115px;
    padding-right: 115px;
  }
`;

export const ColumnContainer = styled(Flex)`
  width: 100%;
  max-width: 1440px;
  flex-direction: column;
  gap: 20px;
  alignself: flex-start;
  @media ${device.mobileS} and ${maxDevice.laptop} {
    max-width: 90vw;
  }
`;
