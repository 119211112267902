import styled from "styled-components";
//import { devices } from "src/constants";
import { Constants, Flex } from "@nubeteck/components";

const { device, maxDevice } = Constants;

export const LargeHeadingStyled = styled.h2`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-weight: bolder;
  font-size: 22px;
  line-height: 35px;
  margin: 0px 0px 10px 0px;

  @media ${device.laptop} {
    font-size: 24px;
  }
`;
export const HeadingSubtitleStyled = styled.h4`
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  font-size: 14px;
  line-height: 35px;
  margin: -10px 0px 10px 0px;

  @media ${device.laptop} {
    font-size: 14px;
  }
`;
export const MediumLargeHeadingStyled = styled.h2`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-weight: bolder;
  font-size: 19px;

  @media ${device.mobileS} and ${maxDevice.tablet} {
    font-size: 23px;
  }
`;
export const MediumHeadingStyled = styled.h2`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-weight: bolder;
  font-size: 13px;

  @media ${device.mobileS} and ${maxDevice.tablet} {
    font-size: 20px;
  }
`;
export const XsHeadingStyled = styled.p`
  color: #2a2a2a;
  font-weight: bolder;
  font-size: 13px;
  margin: 8px 0px;
`;
export const SmallHeadingStyled = styled.h4`
  color: ${({ theme }) => theme.colors.colorAccent};
  @media ${device.mobileS} and ${maxDevice.tablet} {
    font-size: 15px;
  }
`;
export const RedTextStyled = styled.span`
  color: #ac0000;
  font-weight: 600;
`;
export const SectionHeaderStyled = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  // height: 40px;
  align-items: baseline;
  flex-wrap:wrap;
  padding-bottom: 10px;
  @media ${device.mobileS} and ${maxDevice.tablet} {
    flex-direction: column;
    height: auto;
    flex-wrap:none;
  }

  // prueba
@media (min-width: 1180px) {
  position: sticky;
  top: 60px;
  padding-top: 20px;
  z-index: 14;
  background-color: ${({ theme }) => theme.colors.colorBgBase};
`;
export const SectionFiltersHeaderStyled = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  // height: 40px;
  align-items: baseline;
  flex-wrap: wrap;
  padding-bottom: 10px;
  @media ${device.mobileS} and ${maxDevice.tablet} {
    flex-direction: column;
    height: auto;
    flex-wrap: none;
  }
`;
export const SmallMessageStyled = styled.div`
  font-size: 12.5px;
  p {
    margin: 6px 0px;
  }
`;
