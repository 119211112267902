import React from "react";
import { SmallHeadingStyled } from "../texts/texts.styled";
import { themeColors } from "src/core";

type Action = {
  accion: string;
  estudiante: string;
  fechaAsistencia: string;
  estadoAnterior: string;
  estadoNuevo: string;
};

export interface TimelineData {
  modificadoPor: string;
  dateTime: string;
  actions: Action[];
}

interface TimelineProps {
  data: TimelineData[];
}

const TimelineCustom: React.FC<TimelineProps> = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            marginTop: "20px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <SmallHeadingStyled style={{ padding: "0px 10px 0px 10px" }}>
              {item.dateTime}:
            </SmallHeadingStyled>
            <p style={{ color: themeColors.colorAccent, margin: "0px" }}>
              {item.actions[0].accion} {item.actions[0].estudiante} para la fecha{" "}
              {item.actions[0].fechaAsistencia}
            </p>
          </div>
          {item.actions.map((action, actionIndex) => (
            <div key={actionIndex}>
              {actionIndex > 0 && (
                <p
                  style={{ color: themeColors.colorAccent, paddingLeft: "10px", marginTop: "0px" }}
                >
                  {action.accion} {action.estudiante} para la fecha {action.fechaAsistencia}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 25,
                }}
              >
                <p
                  style={{
                    color: themeColors.colorGray,
                    paddingLeft: "10px",
                    margin: "0px",
                    alignContent: "center",
                  }}
                >
                  Estatus:
                </p>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      backgroundColor: "#F2D5D3",
                      height: "30px",
                      width: "auto",
                      padding: "0px 15px 0px 15px",
                      marginLeft: "10px",
                      alignContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                      textDecorationLine: "line-through",
                    }}
                  >
                    {action.estadoAnterior}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#D3F2D8",
                      height: "30px",
                      width: "auto",
                      padding: "0px 15px 0px 15px",
                      marginLeft: "10px",
                      alignContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                    }}
                  >
                    {action.estadoNuevo}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h4
              style={{
                padding: "0px 10px 0px 10px",
                color: themeColors.colorTextHeading,
              }}
            >
              Modificado por:
            </h4>
            <p>{item.modificadoPor}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default TimelineCustom;
