import { CardStyled } from "src/components/custom/cards/card.styled";
import { Drawer, Flex, Image } from "antd";
import styled from "styled-components";
import { FlexStyled } from "src/components/registration/register-confirmation/confirmation.styled";
import { device, maxDevice } from "@nubeteck/components/build/utils/constansts";

export const DetailGridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(6, 1fr);
  gap: 40px 48px;
  width: 100%;

  @media ${device.mobileS} and ${maxDevice.laptop} {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const GeneralInfoDetailContainer = styled.div`
  grid-area: 1 / 1 / 4 / 3;
  .ant-card-body {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    text-align: center;
    position: relative;
    .info-container {
      font-size: 15px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .label-info {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.colorAccent};
      }
    }
  }
`;
export const RightListContainer = styled.div`
  grid-area: 1 / 3 / 4 / 7;
`;
export const RowDetailContainer = styled.div`
  grid-area: 4 / 1 / 5 / 7;
`;
export const BelowListContainer = styled.div`
  grid-area: 5 / 1 / 8 / 7;
`;

export const ImagePersonTable = styled(Image)`
  border-radius: 50%;
`;
export const ScheduleContainer = styled(CardStyled)`
  .ant-card-body {
    padding: 0;
  }

  .ant-table .ant-table-body .ant-table-row td.ant-table-cell {
    padding: 0;
    height: 70px;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.primary100};
      font-size: 13px;
      .aula {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.colorAccent};
      }
    }
  }
`;
export const SectionTitle = styled.h3`
  font-size: 19px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 0;
`;
export const StateFloatContainer = styled(Flex)`
  // width: 100px;
  position: absolute;
  top: 15px;
  left: 25px;
  gap: 10px;
  font-size: 15px;
  align-items: center;
  font-weight: 700;
`;
export const FlexFiltersContainer = styled(FlexStyled)`
  gap: 30px;
  align-items: end;
  justify: space-between;
  margin-bottom: 30px;
  margin-top: 0;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const MediumDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 515px !important;
    height: 80px;
  }
`;
