import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon, Select } from "@nubeteck/components";
import { ArrayUtils } from "@nubeteck/utils";
import { Checkbox, Input, Layout, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useCreateRequestAdminMutation,
  useGetDataSourcesQuery,
  useGetFieldTypesQuery,
  useGetRequestByIdAdminMutation,
  useGetRequestsAdminMutation,
  useGetRequestTypesQuery,
  useUpdateRequestAdminMutation,
  useUpdateRequestStateAdminMutation,
} from "src/services/requestsServices";
import { useGetServicesMutation } from "src/services/servicesServices";

const { Content } = Layout;
interface IOption {
  opcionId: number;
  opcion: string;
}

interface ICampo {
  campoId: number;
  esObligatorio: boolean;
  campoNombre: string;
  tipoCampoId: number;
  opciones?: IOption[] | string;
  fuenteDatos?: number;
  informacionAdicional: string;
}

interface ISolicitudes {
  solicitudId: number;
  estadoId: number;
  solicitudNombre: string;
  campos: ICampo[];
}

const RequestsConfig: React.FC = () => {
  const [form] = Form.useForm<ISolicitudes>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requestId, setRequestId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [solicitudTipoId, setSolicitudTipoId] = React.useState();

  const formularioCampos = Form.useWatch(["campos"], form);
  const formRef = React.useRef<FormInstance>(null);

  const { data: tiposCampos } = useGetFieldTypesQuery({ refetchOnMountOrArgChange: true });
  const { data: fuentesDatos } = useGetDataSourcesQuery({ refetchOnMountOrArgChange: true });
  const { data: solicitudesTipos } = useGetRequestTypesQuery({ refetchOnMountOrArgChange: true });
  const [getServices, { data: servicios }] = useGetServicesMutation();
  const [getRequests, { data, isLoading }] = useGetRequestsAdminMutation();
  const [
    createRequest,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate },
  ] = useCreateRequestAdminMutation();
  const [getRequestById, { data: requestById, isLoading: isLoadingById }] =
    useGetRequestByIdAdminMutation();
  const [
    updateRequest,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
  ] = useUpdateRequestAdminMutation();
  const [
    updateRequestState,
    {
      isLoading: isLoadingState,
      isSuccess: isSuccessState,
      isError: isErrorState,
      error: errorState,
    },
  ] = useUpdateRequestStateAdminMutation();

  React.useEffect(() => {
    getRequests();
    getServices();
  }, [
    getRequests,
    getServices,
    isSuccessCreate,
    isSuccessUpdate,
    isErrorCreate,
    isErrorUpdate,
    isSuccessState,
  ]);

  React.useEffect(() => {
    if (isErrorCreate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
    }
    setRequestId(0);
    setDrawerOpen(false);
  }, [isSuccessCreate, form, isErrorCreate]);

  React.useEffect(() => {
    if (isErrorUpdate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setRequestId(0);
    }

    setDrawerOpen(false);
  }, [isSuccessUpdate, form, isErrorUpdate]);

  React.useEffect(() => {
    if (isErrorState) toast.error(errorState?.data?.message, toastErrorStyle);

    if (isSuccessState) {
      toast.success("Proceso de selección actualizado correctamente", toastSuccessStyle);
    }
  }, [errorState, isSuccessState, isErrorState]);

  const onClickEditRequest = React.useCallback(
    (record: ISolicitudes) => {
      setRequestId(record.solicitudId);
      getRequestById(record.solicitudId);
    },
    [getRequestById],
  );

  React.useEffect(() => {
    if (requestById?.data) {
      setSolicitudTipoId(requestById.data.solicitudTipoId);
      form.setFieldsValue({
        ...requestById.data,
        campos: requestById.data?.campos?.map((ca: ICampo) => ({
          campoNombre: ca.campoNombre,
          esObligatorio: ca.esObligatorio,
          informacionAdicional: ca.informacionAdicional,
          tipoCampoId: ca.tipoCampoId,
          campoId: ca.campoId,
          fuenteDatos: ca.fuenteDatos,
          opciones:
            ca.opciones && Object.keys(ca.opciones)?.length > 0
              ? ca.fuenteDatos == 1
                ? ca.opciones
                : (ca.opciones as IOption[])[0]?.opcion
              : undefined,
        })),
      });
      setDrawerOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestById]);

  const changeRequestState = React.useCallback(
    (id: number) => {
      updateRequestState(id);
    },
    [updateRequestState],
  );

  const submit = (values: ISolicitudes) => {
    const v = {
      ...values,
      campos: values?.campos?.map((ca) => ({
        ...ca,
        opciones:
          ca.opciones && Object.keys(ca.opciones[0])?.length > 0
            ? ca.fuenteDatos == 1
              ? ca.opciones
              : [{ opcion: ca.opciones }]
            : null,
      })),
    };
    if (requestId !== 0) {
      updateRequest({
        ...v,
        id: requestId,
      });
    } else {
      createRequest(v);
    }
  };

  const fuentesDatosOptions = ArrayUtils.selectLabelValue(
    fuentesDatos?.data ?? [],
    "fuenteDatoNombre",
    "fuenteDatoId",
  );

  const tiposCamposOptions = ArrayUtils.selectLabelValue(
    tiposCampos?.data ?? [],
    "tipoCampoNombre",
    "tipoCampoId",
  );
  const serviciosOptions = ArrayUtils.selectLabelValue(
    servicios?.data ?? [],
    "servicioNombre",
    "servicioId",
  );

  const solicitudesTiposOptions = ArrayUtils.selectLabelValue(
    solicitudesTipos?.data ?? [],
    "tipoNombre",
    "solicitudTipoId",
  );

  const columns = React.useMemo<ColumnsType<ISolicitudes>>(
    () => [
      {
        title: "Nombre",
        dataIndex: "solicitudNombre",
      },
      {
        title: "Fecha de creación",
        dataIndex: "fechaCreacion",
      },
      {
        title: "Estado",
        dataIndex: "estadoNombre",
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.solicitudId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => onClickEditRequest(record),
                },
                {
                  key: "2",
                  label: "Eliminar",
                  icon: <Icon name={record.estadoId === 2 ? "" : ""} color="#A7A7A7" />,
                  onClick: () => changeRequestState(record.solicitudId),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditRequest, changeRequestState],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <SectionHeaderStyled>
          <LargeHeadingStyled>Solicitudes</LargeHeadingStyled>
          <Flex style={{ gap: "15px" }}>
            <Button
              title=""
              type="primary"
              loading={isLoadingById}
              onClick={() => {
                setDrawerOpen(true);
                form.resetFields();
              }}
            >
              Nuevo formato de solicitud
            </Button>
          </Flex>
        </SectionHeaderStyled>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<ISolicitudes>
            loading={isLoading || isLoadingCreate || isLoadingUpdate || isLoadingState}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.solicitudId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title={
              requestId !== 0 ? "Edición de formato de solicitud" : "Nuevo formato de solicitud"
            }
            open={drawerOpen}
            onClose={() => {
              setRequestId(0);
              setDrawerOpen(false);
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              onFinish={submit}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="solicitudNombre"
                label="Nombre de la solicitud"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="descripcion" hasFeedback label="Descripción">
                <Input />
              </Form.Item>

              <Form.Item
                name="solicitudTipoId"
                label="Tipo de solicitud"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={(e) => setSolicitudTipoId(e)}
                  value={solicitudTipoId}
                  options={solicitudesTiposOptions ?? []}
                  placeholder="Tipo de solicitud"
                  allowClear
                />
              </Form.Item>
              {solicitudTipoId === 1 && (
                <Form.Item
                  name="servicioId"
                  label="Servicio"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    options={serviciosOptions ?? []}
                    placeholder="Servicio"
                    allowClear
                  />
                </Form.Item>
              )}

              {/* Nest Form.List */}
              <Form.Item label="Campos">
                <Form.List name={["campos"]}>
                  {(subFields, subList) => (
                    <div>
                      {subFields.map((subField, index) => (
                        <div key={subField.key} style={{ position: "relative", marginTop: 16 }}>
                          <Button
                            type="text"
                            style={{ position: "absolute", top: -15, right: 0 }}
                            onClick={() => {
                              subList.remove(subField.name);
                            }}
                          >
                            <Icon name="" color="#bcbbbb" />
                          </Button>

                          <Form.Item
                            name={[subField.name, "campoNombre"]}
                            label="Nombre del campo"
                            required
                          >
                            <Input placeholder="Nombre del campo" />
                          </Form.Item>
                          <Form.Item
                            name={[subField.name, "tipoCampoId"]}
                            label="Tipo de campo"
                            hasFeedback
                            required
                          >
                            <Select
                              allowClear
                              size="large"
                              options={tiposCamposOptions ?? []}
                              placeholder="Tipo de campo"
                            />
                          </Form.Item>
                          {formularioCampos && formularioCampos[index]?.tipoCampoId == 4 && (
                            <>
                              <Form.Item
                                name={[subField.name, "fuenteDatos"]}
                                label="Fuente de datos"
                                hasFeedback
                                required
                              >
                                <Select
                                  allowClear
                                  size="large"
                                  options={fuentesDatosOptions ?? []}
                                  placeholder="Tipo de campo"
                                />
                              </Form.Item>
                              {formularioCampos && formularioCampos[index]?.fuenteDatos == 1 ? (
                                <Form.Item
                                  label="Opciones"
                                  required
                                  style={{
                                    backgroundColor: "#f1f1f6",
                                    borderRadius: 10,
                                    padding: 25,
                                  }}
                                >
                                  <Form.List name={[subField.name, "opciones"]}>
                                    {(subOptions, subOpt) => (
                                      <div>
                                        {subOptions.map((subOption) => (
                                          <div
                                            key={subOption.key}
                                            style={{ position: "relative", marginTop: 16 }}
                                          >
                                            <Button
                                              type="text"
                                              style={{ position: "absolute", top: -15, right: 0 }}
                                              onClick={() => {
                                                subOpt.remove(subOption.name);
                                              }}
                                            >
                                              <Icon name="" color="#bcbbbb" />
                                            </Button>

                                            <Form.Item
                                              name={[subOption.name, "opcion"]}
                                              label="Nombre de la opción"
                                              required
                                            >
                                              <Input placeholder="Opción" />
                                            </Form.Item>
                                          </div>
                                        ))}
                                        <Button type="dashed" onClick={() => subOpt.add()} block>
                                          + Añadir opción
                                        </Button>
                                      </div>
                                    )}
                                  </Form.List>
                                </Form.Item>
                              ) : formularioCampos &&
                                (formularioCampos[index]?.fuenteDatos == 2 ||
                                  formularioCampos[index]?.fuenteDatos == 3) ? (
                                <Form.Item
                                  name={[subField.name, "opciones"]}
                                  label={
                                    formularioCampos[index]?.fuenteDatos == 2
                                      ? "Nombre del procedimiento"
                                      : "Nombre del web service"
                                  }
                                  required
                                >
                                  <Input placeholder="Nombre" />
                                </Form.Item>
                              ) : (
                                <></>
                              )}
                            </>
                          )}

                          <Form.Item
                            name={[subField.name, "esObligatorio"]}
                            valuePropName="checked"
                            hasFeedback
                          >
                            <Checkbox>Es obligatorio</Checkbox>
                          </Form.Item>
                          <Form.Item
                            name={[subField.name, "informacionAdicional"]}
                            label="Información adicional"
                          >
                            <Input placeholder="Información adicional" />
                          </Form.Item>
                        </div>
                      ))}
                      <Button type="dashed" onClick={() => subList.add()} block>
                        + Añadir campo
                      </Button>
                    </div>
                  )}
                </Form.List>
              </Form.Item>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingCreate || isLoadingState || isLoadingUpdate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default RequestsConfig;
