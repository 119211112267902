import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon } from "@nubeteck/components";

import { Input, Layout, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useDeleteSponsorMutation,
  useCreateSponsorMutation,
  useGetSponsorsMutation,
  useUpdateSponsorMutation,
} from "src/services";

const { Content } = Layout;

interface ISponsor {
  patrocinioId: number;
  estadoId: number;
  patrocinioNombre: string;
  codigo: string;
}

const Sponsors: React.FC = () => {
  const [form] = Form.useForm<ISponsor>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patrocinioId, setPatrocinioId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const formRef = React.useRef<FormInstance>(null);
  const { resetFields, setFieldsValue, validateFields } = form;
  const [getSponsors, { data, isLoading }] = useGetSponsorsMutation();
  const [
    createSponsor,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, error: errorCreate },
  ] = useCreateSponsorMutation();
  const [
    updateTemplate,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate },
  ] = useUpdateSponsorMutation();
  const [
    changeSponsorState,
    { isLoading: isLoadingState, isSuccess: isSuccessState, error: errorState },
  ] = useDeleteSponsorMutation();

  React.useEffect(() => {
    getSponsors();
  }, [getSponsors, isSuccessCreate, isSuccessUpdate, errorCreate, errorUpdate, isSuccessState]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (errorCreate) toast.error(`Error: ${errorCreate.data?.message}`, toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      resetFields();
      setPatrocinioId(0);
    }
  }, [isSuccessCreate, resetFields, errorCreate]);

  React.useEffect(() => {
    setDrawerOpen(false);
    console.log(errorUpdate, "errrr");
    if (errorUpdate) toast.error(`Error: ${errorUpdate.data?.message}`, toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      resetFields();
      setPatrocinioId(0);
    }
  }, [isSuccessUpdate, resetFields, errorUpdate]);

  React.useEffect(() => {
    if (errorState) {
      toast.error(`Error: ${errorState.data?.message}`, toastErrorStyle);
    }

    if (isSuccessState) {
      toast.success("Estado actualizado correctamente.", toastSuccessStyle);
    }
  }, [errorState, isSuccessState]);

  const onClickEditarPatrocinador = React.useCallback(
    (record: ISponsor) => {
      setPatrocinioId(record.patrocinioId);
      setFieldsValue(record);

      setDrawerOpen(true);
    },
    [setFieldsValue],
  );

  const cambiarEstadoPatrocinador = React.useCallback(
    (value: number, id: number) => {
      changeSponsorState({ estadoId: value === 11 ? 12 : 11, id });
    },
    [changeSponsorState],
  );

  const submit = () => {
    validateFields(["patrocinioNombre", "codigo"])
      .then((values) => {
        const v = {
          ...values,
        };
        if (patrocinioId !== 0) {
          updateTemplate({
            ...v,
            id: patrocinioId,
          });
        } else {
          createSponsor(v);
        }
      })
      .catch(() => {
        toast.error(`Algunos campos necesitan ser revisados.`);
      });
  };

  const columns = React.useMemo<ColumnsType<ISponsor>>(
    () => [
      {
        title: "Código",
        dataIndex: "codigo",
      },
      {
        title: "Patrocinador",
        dataIndex: "patrocinioNombre",
      },
      {
        title: "Estado",
        dataIndex: "estadoId",
        render: (_, record) => (record.estadoId == 11 ? "Activo" : "Inactivo"),
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.patrocinioId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => onClickEditarPatrocinador(record),
                },
                {
                  key: "2",
                  label: record.estadoId === 11 ? "Inactivar" : "Activar",
                  icon: <Icon name={record.estadoId === 11 ? "" : ""} color="#A7A7A7" />,
                  onClick: () => cambiarEstadoPatrocinador(record.estadoId, record.patrocinioId),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarPatrocinador, cambiarEstadoPatrocinador],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <LargeHeadingStyled>Patrocinadores</LargeHeadingStyled>

          <Button
            title=""
            type="primary"
            loading={false}
            onClick={() => {
              setDrawerOpen(true);
              resetFields();
            }}
          >
            Nuevo patrocinador
          </Button>
        </Flex>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<ISponsor>
            loading={isLoading || isLoadingCreate || isLoadingUpdate || isLoadingState}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.patrocinioId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title={patrocinioId !== 0 ? "Edición de patrocinador" : "Nuevo patrocinador"}
            open={drawerOpen}
            onClose={() => {
              setPatrocinioId(0);
              setDrawerOpen(false);
              resetFields();
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="codigo"
                label="Código del patrocinador"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="patrocinioNombre"
                label="Nombre del patrocinador"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>

              <Button
                onClick={() => submit()}
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingCreate || isLoadingState || isLoadingUpdate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default Sponsors;
