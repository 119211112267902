import {
  useCreateSelectionDateMutation,
  useGetRulesMutation,
  useGetRulesVerificationMutation,
  useGetSelectionDatesMutation,
  useUpdateSelectionDateMutation,
} from "src/services/selectionDatesServices";
import {
  MediumLargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Flex,
  Form,
  Icon,
  InputNumber,
  Text,
} from "@nubeteck/components";
import { Collapse, Input, Layout, List, Table, Select, Drawer } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import ConfigurationInput from "../generalSettings/settingsInput";
import { IPeriodosFechas } from "@/Interfaces/datesSettings";
import { PeriodEstimationVariants } from "../period-estimation-variants";
import { ArrayUtils } from "@nubeteck/utils";
import { useGetAdminPeriodsQuery } from "src/services";
import { useGetCareersByStudentIdMutation } from "src/services/importsService";

const { Content } = Layout;

const DateSettings: React.FC = () => {
  const [form] = Form.useForm<IPeriodosFechas>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [procesoId, setProcesoId] = React.useState(0);
  const [periodoId, setPeriodoId] = React.useState(0);
  const [matriculaVerificacion, setMatriculaVerificacion] = React.useState<string>();
  const [curriculumVerificacion, setCurriculumVerificacion] = React.useState<string>();
  const [periodoVerificacion, setPeriodoVerificacion] = React.useState<string>();
  const [anioVerificacion, setAnioVerificacion] = React.useState<number>();
  const [periodoVariantes, setPeriodoVariantes] = React.useState<IPeriodosFechas>();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerVerificacionOpen, setDrawerVerificacionOpen] = React.useState(false);

  const [anioFiltro, setAnioFiltro] = React.useState<number>(new Date().getFullYear());

  const formRef = React.useRef<FormInstance>(null);
  const [getDates, { data, isLoading }] = useGetSelectionDatesMutation();
  const [getRules, { data: rulesData }] = useGetRulesMutation();
  const [getCareersByStudentId, { isLoading: isLoadingCareers, data: dataCareers }] =
    useGetCareersByStudentIdMutation();

  const { data: periodos } = useGetAdminPeriodsQuery(anioVerificacion, {
    refetchOnMountOrArgChange: true,
  });
  const [
    createDate,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate },
  ] = useCreateSelectionDateMutation();
  const [
    updateDate,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
  ] = useUpdateSelectionDateMutation();
  const [
    getRulesVerification,
    { data: rulesVerification, isLoading: isLoadingVerification, error: errorVerification },
  ] = useGetRulesVerificationMutation();

  const carrerasOptions = ArrayUtils.selectLabelValue(
    dataCareers?.data ?? [],
    "carreraNombre",
    "carreraId",
  );

  React.useEffect(() => {
    if (anioFiltro) {
      getDates(anioFiltro);
    }
  }, [getDates, isSuccessCreate, isSuccessUpdate, isErrorCreate, isErrorUpdate, anioFiltro]);

  useEffect(() => {
    if (periodoId !== 0) {
      getRules(periodoId);
    }
  }, [getRules, periodoId]);

  React.useEffect(() => {
    if (isErrorUpdate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setProcesoId(0);
    }
    setDrawerOpen(false);
  }, [isSuccessUpdate, form, isErrorUpdate]);
  React.useEffect(() => {
    if (errorVerification) toast.error(errorVerification?.data?.message, toastErrorStyle);
  }, [errorVerification]);

  const onClickEditarPeriodo = React.useCallback(
    (record: IPeriodosFechas) => {
      setProcesoId(record.procesoId);
      setPeriodoId(record.periodoId);
      form.setFieldsValue({
        terminosYCondiciones: record.terminosYCondiciones,
        reglaContado: record.reglaContado,
        reglaContadoDolares: record.reglaContadoDolares,
        reglaCuotas: record.reglaCuotas,
        reglaCuotasDolares: record.reglaCuotasDolares,
        reglaContadoAmazon: record.reglaContadoAmazon,
        reglaContadoAmazonDolares: record.reglaContadoAmazonDolares,
        reglaCuotasAmazon: record.reglaCuotasAmazon,
        reglaCuotasAmazonDolares: record.reglaCuotasAmazonDolares,
      });
      setDrawerOpen(true);
    },
    [form],
  );

  const submit = (values: any) => {
    if (procesoId !== 0) {
      updateDate({
        ...values,
        id: procesoId,
      });
    } else {
      createDate(values);
    }
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const periodosOptions = ArrayUtils.selectLabelValue(periodos?.data ?? [], "nombre", "idPeriodo");

  const columns = React.useMemo<ColumnsType<IPeriodosFechas>>(
    () => [
      {
        title: "Año",
        dataIndex: "anio",
      },
      {
        title: "Periodo",
        dataIndex: "periodoNombre",
      },
      {
        title: "Fecha de inicio de regulares",
        dataIndex: "fechaInicio",
      },
      {
        title: "Fecha de fin de regulares",
        dataIndex: "fechaFin",
      },
      {
        title: "Fecha de inicio primer ingreso",
        dataIndex: "fechaInicioPrimerIngreso",
      },
      {
        title: "Fecha de fin primer ingreso",
        dataIndex: "fechaFinPrimerIngreso",
      },
      {
        title: "Estado",
        dataIndex: "estadoAbierta",
        render: (_, record) => (record.estadoAbierta ? "Abierto" : "Cerrado"),
      },
      {
        title: "Limitado a ciertas carreras",
        dataIndex: "limitadoACarreras",
        render: (_, record) => (record.limitadoACarreras ? "Si" : "No"),
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.procesoId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="A7A7A7" />,
                  onClick: () => onClickEditarPeriodo(record),
                },
                {
                  key: "2",
                  label: "Variantes",
                  icon: <Icon name="" color="A7A7A7" />,
                  // onClick: () => navigate(`period-estimation-variants/${record.periodoId}`),
                  onClick: () => {
                    setPeriodoVariantes(record);
                  },
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarPeriodo],
  );

  return !periodoVariantes ? (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <SectionHeaderStyled>
          <MediumLargeHeadingStyled>Periodos de matrícula</MediumLargeHeadingStyled>
          <Flex style={{ gap: "15px" }}>
            <Button onClick={() => setDrawerVerificacionOpen(true)}>Verificación de reglas</Button>
            <Form.Item label="Filtro por año">
              <Input
                type="number"
                size="small"
                defaultValue={anioFiltro}
                onChange={(e) => setAnioFiltro(+e.target.value)}
                placeholder="Año"
                style={{ height: "35px", marginRight: "15px" }}
              />
            </Form.Item>
          </Flex>
        </SectionHeaderStyled>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IPeriodosFechas>
            loading={isLoading || isLoadingCreate || isLoadingUpdate}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.procesoId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title="Verificación de reglas de estimación según ID"
            open={drawerVerificacionOpen}
            onClose={() => {
              setMatriculaVerificacion(undefined);
              setPeriodoVerificacion(undefined);
              setCurriculumVerificacion(undefined);
              setDrawerVerificacionOpen(false);
            }}
          >
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              onFinish={() =>
                getRulesVerification({
                  matriculaVerificacion,
                  periodoVerificacion,
                  anioVerificacion,
                  curriculumVerificacion,
                })
              }
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="matricula"
                label="ID de estudiante"
                hasFeedback
                initialValue={matriculaVerificacion}
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setMatriculaVerificacion(e.target.value);
                    getCareersByStudentId(e.target.value);
                  }}
                  value={matriculaVerificacion}
                />
              </Form.Item>
              <Form.Item
                name="carrera"
                label="Currículum"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select
                  size="large"
                  allowClear
                  options={carrerasOptions}
                  onChange={(e) => setCurriculumVerificacion(e)}
                  value={curriculumVerificacion}
                  loading={isLoadingCareers}
                  placeholder="Carrera"
                />
              </Form.Item>
              <Form.Item
                name="anio"
                label="Año"
                initialValue={anioVerificacion}
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <InputNumber onChange={(e) => setAnioVerificacion(e ? +e : 0)} controls={false} />
              </Form.Item>
              <Form.Item
                name="periodoId"
                label="Periodo"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select
                  options={periodosOptions}
                  filterOption={filterOption}
                  placeholder="Seleccione un periodo"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  onChange={(e) => setPeriodoVerificacion(e)}
                  value={periodoVerificacion}
                />
              </Form.Item>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingVerification}
              >
                Verificar reglas de estimación
              </Button>
              {rulesVerification?.data && (
                <>
                  <Divider orientation="left">Reglas asignadas a {matriculaVerificacion}</Divider>
                  <List
                    dataSource={Object.keys(rulesVerification.data).map((rule) => ({
                      reglaNombre: rule,
                      regla: rulesVerification.data[rule],
                    }))}
                    renderItem={(item: any) => (
                      <List.Item>
                        <Text mark>{item.reglaNombre}</Text> {item.regla}
                      </List.Item>
                    )}
                  />
                </>
              )}
            </Form>
          </Drawer>
          <Drawer
            placement="right"
            title={
              procesoId !== 0
                ? `Edición de periodo de selección ${data?.data?.find(
                    (d: IPeriodosFechas) => d.procesoId === procesoId,
                  ).periodoNombre}`
                : "Nuevo periodo de selección"
            }
            open={drawerOpen}
            onClose={() => {
              setProcesoId(0);
              setDrawerOpen(false);
            }}
          >
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              onFinish={submit}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              {/* <Form.Item name="anio" label="Año" hasFeedback>
                <Input type="number" min={0} onChange={(e) => setAnioForm(+e.target.value)} />
              </Form.Item>
              <Form.Item name="periodoId" label="Periodo" hasFeedback>
                <Select size="large" options={periodosOptions} placeholder="Periodo" showSearch />
              </Form.Item>
              <Form.Item name="fechaInicio" label="Fechas límites" hasFeedback>
                <DatePicker.RangePicker format={"DD/MM/YYYY"} />
              </Form.Item>
              <Form.Item
                name="limitadoACarreras"
                label="¿El proceso está limitado a carreras?"
                hasFeedback
              >
                <Radio.Group
                  options={[
                    { label: "Si", value: true },
                    { label: "No", value: false },
                  ]}
                  onChange={onChangeLimitadoACarreras}
                />
              </Form.Item>
              {isCareersSelectVisible && (
                <Form.Item
                  name="carreras"
                  label="Carreras para las que está disponible"
                  hasFeedback
                >
                  <Select
                    size="large"
                    options={carrerasOptions}
                    mode="multiple"
                    placeholder="Carreras para la selección"
                  />
                </Form.Item>
              )} */}

              <Form.Item
                name="terminosYCondiciones"
                label="Términos y condiciones"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                {drawerOpen && (
                  <ConfigurationInput
                    data={{
                      id: 1,
                      label: "",
                      nombre: "terminosYCondiciones",
                      tipo: "Editor",
                      valor: form.getFieldValue("terminosYCondiciones"),
                      descripcion: "Específique los términos y condiciones según el periodo.",
                    }}
                    onChange={(value) => form.setFieldValue("terminosYCondiciones", value ?? null)}
                  />
                )}
              </Form.Item>
              <Collapse
                items={[
                  {
                    key: 1,
                    label: (
                      <SmallHeadingStyled style={{ margin: 0 }}>
                        Reglas de estimación
                      </SmallHeadingStyled>
                    ),
                    children: (
                      <div>
                        {" "}
                        <Form.Item
                          name="reglaContado"
                          label="Pago único (colones)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaContadoAmazon"
                          label="Pago único con Amazon (colones)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotas"
                          label="Pago a cuotas (colones)"
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotasAmazon"
                          label="Pago a cuotas con Amazon (colones)"
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.colones?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaContadoDolares"
                          label="Pago único (dólares)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaContadoAmazonDolares"
                          label="Pago único con Amazon (dólares)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Este campo es requerido.",
                            },
                          ]}
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotasDolares"
                          label="Pago a cuotas (dólares)"
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reglaCuotasAmazonDolares"
                          label="Pago a cuotas con Amazon (dólares)"
                          style={{ marginBottom: 4 }}
                        >
                          <Select
                            allowClear
                            placeholder="Seleccionar moneda"
                            size="large"
                            options={rulesData?.data?.dolares?.map((ru: any) => ({
                              label: ru.descripcion,
                              value: ru.id,
                            }))}
                          />
                        </Form.Item>
                      </div>
                    ),
                  },
                ]}
                defaultActiveKey={1}
                ghost
                style={{ background: "transparent" }}
              />

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingUpdate || isLoadingCreate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  ) : (
    <PeriodEstimationVariants
      goBack={() => setPeriodoVariantes(undefined)}
      periodo={periodoVariantes}
    />
  );
};

export default DateSettings;
