import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon } from "@nubeteck/components";
import { Input, Layout, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useSetBankStateActiveMutation,
  useSetBankStateInactiveMutation,
  useCreateBankMutation,
  useGetBanksMutation,
  useUpdateBankMutation,
} from "src/services/bankServices";

const { Content } = Layout;

interface IBank {
  bancoId: number;
  estadoId: number;
  estadoNombre: string;
  bancoNombre: string;
  codigoColones: string;
  codigoDolares: string;
}

const Banks: React.FC = () => {
  const [form] = Form.useForm<IBank>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bancoId, setBancoId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const formRef = React.useRef<FormInstance>(null);

  const [getBanks, { data, isLoading }] = useGetBanksMutation();
  const [
    createBank,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate },
  ] = useCreateBankMutation();
  const [
    updateDate,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
  ] = useUpdateBankMutation();
  const [
    setActive,
    { isLoading: isLoadingActive, isSuccess: isSuccessActive, isError: isErrorActive },
  ] = useSetBankStateActiveMutation();
  const [
    setInactive,
    { isLoading: isLoadingInactive, isSuccess: isSuccessInactive, isError: isErrorInactive },
  ] = useSetBankStateInactiveMutation();

  React.useEffect(() => {
    getBanks("");
  }, [
    getBanks,
    isSuccessCreate,
    isSuccessUpdate,
    isErrorCreate,
    isErrorUpdate,
    isSuccessActive,
    isSuccessInactive,
  ]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (isErrorCreate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setBancoId(0);
    }
  }, [isSuccessCreate, form, isErrorCreate]);

  React.useEffect(() => {
    setDrawerOpen(false);
    if (isErrorUpdate) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      form.resetFields();
      setBancoId(0);
    }
  }, [isSuccessUpdate, form, isErrorUpdate]);

  React.useEffect(() => {
    if (isErrorActive || isErrorInactive) {
      toast.error("Ha ocurrido un error actualizando el estado del banco.", toastErrorStyle);
    }

    if (isSuccessActive || isSuccessInactive) {
      toast.success("Datos actualizados correctamente.", toastSuccessStyle);
    }
  }, [isSuccessActive, isSuccessInactive, isErrorActive, isErrorInactive]);

  const onClickEditarBanco = React.useCallback(
    (record: IBank) => {
      setBancoId(record.bancoId);
      form.setFieldsValue({
        ...record,
      });
      setDrawerOpen(true);
    },
    [form],
  );

  const cambiarEstadoBanco = React.useCallback(
    (value: number, id: number) => {
      if (value === 11) {
        setInactive(id);
      } else {
        setActive(id);
      }
    },
    [setActive, setInactive],
  );

  const submit = (values: any) => {
    const v = {
      ...values,
    };
    if (bancoId !== 0) {
      updateDate({
        ...v,
        id: bancoId,
      });
    } else {
      createBank(v);
    }
  };

  const columns = React.useMemo<ColumnsType<IBank>>(
    () => [
      {
        title: "Banco",
        dataIndex: "bancoNombre",
      },
      {
        title: "Código colones",
        dataIndex: "codigoColones",
      },
      {
        title: "Código dólares",
        dataIndex: "codigoDolares",
      },
      {
        title: "Estado",
        dataIndex: "estadoNombre",
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.bancoId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" color="#A7A7A7" />,
                  onClick: () => onClickEditarBanco(record),
                },
                {
                  key: "2",
                  label: record.estadoId === 11 ? "Inactivar" : "Activar",
                  icon: <Icon name={record.estadoId === 11 ? "" : ""} color="#A7A7A7" />,
                  onClick: () => cambiarEstadoBanco(record.estadoId, record.bancoId),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarBanco, cambiarEstadoBanco],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <LargeHeadingStyled>Bancos</LargeHeadingStyled>
          <Flex style={{ gap: "15px" }}>
            <Button
              title=""
              type="primary"
              onClick={() => {
                setDrawerOpen(true);
                form.resetFields();
              }}
            >
              Nuevo banco
            </Button>
          </Flex>
        </Flex>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IBank>
            loading={
              isLoading ||
              isLoadingCreate ||
              isLoadingUpdate ||
              isLoadingActive ||
              isLoadingInactive
            }
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.bancoId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title={bancoId !== 0 ? "Edición de banco" : "Nuevo banco"}
            open={drawerOpen}
            onClose={() => {
              setBancoId(0);
              setDrawerOpen(false);
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              onFinish={submit}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="bancoNombre"
                label="Nombre"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="codigoColones"
                label="Código colones"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="codigoDolares"
                label="Código dólares"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingCreate || isLoadingActive || isLoadingInactive || isLoadingUpdate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default Banks;
