import { RcFile } from "antd/es/upload";

export function getFormData(object: any) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
}

export const getBase64 = async (file: RcFile, callback: (url: string) => void) => {
  await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => callback(resolve(reader.result) as unknown as string);
    reader.readAsDataURL(file);
  });
};

export const downloadExcelBase64: (fileName: string, data: string) => void = (fileName, data) => {
  const aElement = document.createElement("a");
  aElement.setAttribute("download", fileName);
  const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
  aElement.href = href;
  aElement.setAttribute("target", "_blank");
  aElement.click();
  URL.revokeObjectURL(href);
};
