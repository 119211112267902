import { LargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Button } from "@nubeteck/components";
import { Image } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ForbiddenSVG } from "src/assets";

function Required() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        gap: "15px",
      }}
    >
      <Image src={ForbiddenSVG} alt="Sin acceso" preview={false} width={500} />
      <LargeHeadingStyled>No tienes acceso a este sitio</LargeHeadingStyled>
      <Button type="primary" onClick={() => navigate("/")}>
        Volver a la página principal
      </Button>
    </div>
  );
}

export default Required;
