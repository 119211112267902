import {
  LargeHeadingStyled,
  SmallHeadingStyled,
  SmallMessageStyled,
} from "../../../components/custom/texts/texts.styled";
import { Box, Button, Card, Drawer, Flex, Icon, Text } from "@nubeteck/components";
import { Dropdown, Form, Image, InputNumber, Layout, Modal, Select, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { NoResultsSVG } from "src/assets";
import { toastSuccessStyle } from "src/constants";
import { ArrayUtils } from "@nubeteck/utils";
import {
  useCreateRequestMutation,
  useGetFieldTypesQuery,
  useGetRequestByIdMutation,
  useGetRequestsAdminMutation,
  useGetRequestsMutation,
  useUpdateRequestStateMutation,
} from "src/services/requestsServices";
import FormItem from "antd/es/form/FormItem";
import ConfigurationInput from "../../admin-pages/generalSettings/settingsInput";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store/store";

const { Content } = Layout;

interface ISolicitud {
  solicitudId: number;
  estudianteSolicitudId: number;
  solicitudNombre: string;
  estadoId: number;
  estadoNombre: string;
  fecha: string;
}

const Requests: React.FC = () => {
  const [form] = Form.useForm<ISolicitud>();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);

  const [modal, contextHolder] = Modal.useModal();

  const [getRequests, { data, isLoading }] = useGetRequestsMutation();
  const [getRequestById, { data: requestById, isLoading: isLoadingById }] =
    useGetRequestByIdMutation();
  const [createRequest, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] =
    useCreateRequestMutation();
  const [deleteRequest, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] =
    useUpdateRequestStateMutation();
  const [getConcepts, { data: conceptos }] = useGetRequestsAdminMutation();
  const { isATeacher } = useSelector((state: RootState) => state.user);

  //const [fechasFiltro, setFechasFiltro] = React.useState({ fechaInicio: null, fechaFin: null });
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { data: tiposCampos } = useGetFieldTypesQuery();

  const navigate = useNavigate();

  const solicitudesConceptos = ArrayUtils.selectLabelValue(
    conceptos?.data,
    "solicitudNombre",
    "solicitudId",
  );

  const obtenerSolicitudesRealizadas = useCallback(() => {
    getRequests({
      rowsCount,
      page: pageNumber,
    });
  }, [rowsCount, pageNumber, getRequests]);

  useEffect(() => {
    getConcepts();
  }, [getConcepts]);

  useEffect(() => {
    obtenerSolicitudesRealizadas();
  }, [isSuccessDelete, obtenerSolicitudesRealizadas]);

  useEffect(() => {
    if (isSuccessCreate) {
      toast.success("¡Petición creada correctamente!", toastSuccessStyle);
      if (isATeacher) obtenerSolicitudesRealizadas();
      else navigate("/cart/requests");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate]);
  useEffect(() => {
    if (isSuccessDelete) {
      toast.success("¡Solicitud eliminada correctamente!", toastSuccessStyle);
      obtenerSolicitudesRealizadas();
    }
  }, [isSuccessDelete, obtenerSolicitudesRealizadas]);

  const submit = (data: any) => {
    const solicitudId: number = data.solicitudId;
    const cantidad: number = data.cantidad ?? 1;
    delete data.solicitudId;
    delete data.cantidad;
    const newData = {
      cantidad,
      fecha: new Date(),
      solicitudId,
      detalle: Object.keys(data).map((campoId: any) => ({
        campoId: +campoId,
        valor: data[campoId] ? `${data[campoId]}` : null,
      })),
    };
    createRequest(newData);
  };
  const changeRequestState = React.useCallback(
    (record: any) => {
      modal.confirm({
        title: `Anular solicitud`,
        icon: <Icon name="" color="#FFC53D" />,
        content: `¿Es seguro que desea eliminar su solicitud de ${record.solicitudNombre}?`,
        okText: "Aceptar",
        onOk: () => {
          deleteRequest(record.estudianteSolicitudId);
        },
        cancelText: "Cancelar",
        style: { top: 200 },
      });
    },
    [deleteRequest, modal],
  );

  const columns = React.useMemo<ColumnsType<ISolicitud>>(
    () => [
      {
        title: "Fecha",
        dataIndex: "fecha",
      },
      {
        title: "Solicitud",
        dataIndex: "solicitudNombre",
      },
      {
        title: "Estado",
        dataIndex: "estadoNombre",
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.solicitudId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "2",
                  label: "Anular solicitud",
                  icon: <Icon name={record.estadoId === 2 ? "" : ""} />,
                  onClick: () => changeRequestState(record),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [changeRequestState],
  );

  return (
    <Spin spinning={isLoadingCreate} size="large" tip="...Cargando asignaturas">
      <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <LargeHeadingStyled>Solicitudes realizadas</LargeHeadingStyled>

          <Button
            title=""
            type="primary"
            onClick={() => {
              setDrawerOpen(true);
              form.resetFields();
            }}
          >
            Nueva solicitud
          </Button>
        </Flex>
        <Content style={{ marginTop: "16px" }}>
          <Card bodyStyle={{ padding: 0, width: "100%" }}>
            <Table<ISolicitud>
              locale={{
                emptyText: (
                  <Box>
                    <Box>
                      <SmallHeadingStyled>No se encontraron solicitudes</SmallHeadingStyled>
                      <Image
                        src={NoResultsSVG}
                        preview={false}
                        alt="Ilustración sin resultados"
                        width={200}
                      />
                    </Box>
                  </Box>
                ),
              }}
              loading={isLoading || isLoadingCreate || isLoadingDelete}
              columns={columns}
              dataSource={data?.data?.result}
              rowKey={(v) => `${v.estudianteSolicitudId}`}
              pagination={{
                onChange(page, pageSize) {
                  setPageNumber(page);
                  setRowsCount(pageSize);
                },
                pageSize: rowsCount,
                current: pageNumber,
                total: data?.data?.pageCount * rowsCount,
                showSizeChanger: true,
                style: { marginRight: "30px" },
              }}
              scroll={{ x: 800 }}
            />
            <Drawer
              title="Nueva solicitud"
              placement="right"
              open={drawerOpen}
              onClose={() => {
                setDrawerOpen(false);
              }}
            >
              {" "}
              <Form
                form={form}
                layout="vertical"
                onFinish={(data) => {
                  setDrawerOpen(false);
                  submit(data);
                }}
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <Form.Item
                  name="solicitudId"
                  label="Solicitud a realizar"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio.",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    size="large"
                    placeholder="Seleccione un tipo de solicitud"
                    options={solicitudesConceptos}
                    onChange={(e) => {
                      getRequestById(e);
                    }}
                  />
                </Form.Item>
                <Form.Item name="cantidad" label="Cantidad" style={{ marginBottom: 4 }} required>
                  <InputNumber
                    placeholder="Cantidad"
                    min="1"
                    defaultValue="1"
                    max="100"
                    controls={false}
                  />
                </Form.Item>
                {requestById?.data !== undefined ? (
                  <>
                    {requestById?.data?.campos?.map((campo: any, index: number) => (
                      <div key={`${campo.tipoCampoId} ${index}`}>
                        <FormItem
                          rules={[
                            {
                              required: campo.esObligatorio,
                              message: campo.esObligatorio ? "Este campo es requerido" : "",
                            },
                          ]}
                          label={campo.campoNombre}
                          name={campo.campoId}
                          help={campo.informacionAdicional}
                        >
                          <ConfigurationInput
                            data={{
                              id: campo.campoId,
                              label: campo.campoNombre,
                              nombre: campo.campoNombre,
                              opciones: campo.opciones?.map((op: any) => ({
                                opcion: op.opcion,
                                opcionId: op.opcion,
                              })),
                              tipo: tiposCampos?.data?.find(
                                (tipo: any) => tipo.tipoCampoId === campo.tipoCampoId,
                              ).tipoCampoNombre,
                              descripcion: campo.informacionAdicional,
                              valor: null,
                            }}
                            onChange={(value) => form.setFieldValue(campo.campoNombre, value)}
                          />
                        </FormItem>
                      </div>
                    ))}
                    <Text>{requestById?.data?.descripcion}</Text>
                  </>
                ) : null}

                <SmallMessageStyled>
                  <SmallHeadingStyled>INFORMACIÓN IMPORTANTE</SmallHeadingStyled>
                  <div>
                    <p>
                      <strong>Plazo de entrega posterior al pago:</strong>
                    </p>
                    <p>-Estudiante activo periodo vigente: 3 días hábiles.</p>
                    <p>-Estudiante inactivo/egresado: 8 días hábiles.</p>
                    <p>-Certificados/insignias/otros: tiempo indicado en el Depto. de Registro.</p>
                    <p>
                      Tanto las constancias como las certificaciones, se emiten en hoja membretada e
                      incluye firma digital. La certificación también incluye el pago del entero
                      fiscal de timbres correspondiente.
                    </p>
                    <p>
                      Cada documento es emitido y válido para una carrera. Si tiene más de una
                      carrera, debe especificar en el espacio de observaciones, de cuál carrera
                      desea el documento. Si requiere el documento de dos o más carreras, debe pagar
                      el rubro correspondiente para cada uno. Si tiene consultas al respecto, antes
                      de hacer la solicitud puede escribir a:{" "}
                      <a
                        href="https://registro@ucenfotec.ac.cr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        registro@ucenfotec.ac.cr
                      </a>
                    </p>
                  </div>
                </SmallMessageStyled>
                <Button
                  type="primary"
                  size="large"
                  loading={isLoadingById}
                  disabled={isLoadingById}
                  htmlType="submit"
                  style={{ alignSelf: "flex-end" }}
                >
                  Enviar solicitud
                </Button>
              </Form>
            </Drawer>
          </Card>
        </Content>
      </Flex>
      {contextHolder}
    </Spin>
  );
};

export default Requests;
