import { useGetAttendanceLogsMutation } from "src/services/attendanceServices";
import { Button, Flex, Spin, Timeline } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HeadingSubtitleStyled,
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "src/components/custom/texts/texts.styled";
import TimelineCustom from "src/components/custom/timeline/timeline";

function HistorialAttendance() {
  const navigate = useNavigate();
  const { seccionId } = useParams();
  const [getAttendanceLogs, { data, isLoading }] = useGetAttendanceLogsMutation();
  // const data = [
  //   {
  //     dateTime: "Ayer - 8:00 AM",
  //     modificadoPor: "Pedro Bard",
  //     actions: [
  //       {
  //         accion: "Se modificó la asistencia de ",
  //         estudiante: "XIMENA PUELLO RODRIGUEZ",
  //         fechaAsistencia: "26/04/2024",
  //         estadoAnterior: "Ausencia injustificada",
  //         estadoColorAnterior: "#F2D5D3",
  //         estadoNuevo: "Tardía justificada",
  //         estadoNuevoColor: "#D3F2D8",
  //       },
  //       {
  //         accion: "Se modificó la asistencia de ",
  //         estudiante: "Franchesca PUELLO RODRIGUEZ",
  //         fechaAsistencia: "26/04/2024",
  //         estadoAnterior: "Ausencia injustificada",
  //         estadoColorAnterior: "#F2D5D3",
  //         estadoNuevo: "Tardía valida",
  //         estadoNuevoColor: "#D3F2D8",
  //       },
  //     ],
  //   },
  //   {
  //     dateTime: "Lunes 20 de Marzo - 8:00 AM",
  //     modificadoPor: "Annita Bard",
  //     actions: [
  //       {
  //         accion: "Se modificó la asistencia de ",
  //         estudiante: "Bill PUELLO RODRIGUEZ",
  //         fechaAsistencia: "26/04/2024",
  //         estadoAnterior: "Ausencia injustificada",
  //         estadoColorAnterior: "#F2D5D3",
  //         estadoNuevo: "Ausencia justificacion medica",
  //         estadoNuevoColor: "#D3F2D8",
  //       },
  //     ],
  //   },
  // ];

  useEffect(() => {
    getAttendanceLogs(seccionId);
  }, [getAttendanceLogs, seccionId]);
  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      {isLoading ? (
        <Spin spinning />
      ) : (
        <>
          <SectionHeaderStyled>
            <div>
              <LargeHeadingStyled>
                Cambios en asistencia del curso {data?.info?.nombre}
              </LargeHeadingStyled>
              <HeadingSubtitleStyled>
                Sección: {data?.info?.seccion} - Horario:{" "}
                {data?.info?.horario?.map((hor: string) => hor)}
              </HeadingSubtitleStyled>
            </div>
            <Button onClick={() => navigate(-1)}>Volver atrás</Button>
          </SectionHeaderStyled>

          <div style={{ paddingTop: "10px" }}>
            <Timeline
              items={data?.logs?.map((item: any, index: number) => ({
                children: (
                  <div key={index}>
                    <TimelineCustom data={[item]} />
                  </div>
                ),
              }))}
            />
          </div>
        </>
      )}
    </Flex>
  );
}

export default HistorialAttendance;
