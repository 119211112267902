import { ISubject } from "@/Interfaces/subject";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Box, Card, Flex, Icon } from "@nubeteck/components";
import { Button, Form, Image, Input, Layout, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect } from "react";
import {
  useGetSubjectsByStudentMutation,
  useWithdrawSubjectByStudentMutation,
} from "src/services/subjectsServices";
import toast from "react-hot-toast";
import { NoResultsSVG } from "src/assets";
import { useGetAdminPeriodsQuery, useGetStudentsMutation } from "src/services";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { ArrayUtils } from "@nubeteck/utils";

const { Content } = Layout;

interface ISubjectByStudent extends ISubject {
  seccionId: number;
  estudianteId: number;
}

const AdminWithdraw: React.FC = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [periodoId, setPeriodoId] = React.useState();
  const [anio, setAnio] = React.useState<number>();
  const [estudiante, setEstudiante] = React.useState();

  const { data: periodos } = useGetAdminPeriodsQuery(anio);
  const [getStudents, { data: students, isLoading: isLoadingStudents }] = useGetStudentsMutation();
  const [getSubjects, { data, isLoading }] = useGetSubjectsByStudentMutation();
  const [
    withdrawSubject,
    { isLoading: isLoadingWithdraw, isSuccess: isSuccessWithdraw, error: errorWithdraw },
  ] = useWithdrawSubjectByStudentMutation();

  const studentsOptions = ArrayUtils.selectLabelValue(
    students?.data?.result ?? [],
    "estudianteNombre",
    "estudianteId",
  );

  const periodosOptions = ArrayUtils.selectLabelValue(periodos?.data ?? [], "nombre", "idPeriodo");

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (estudiante && anio !== 0 && periodoId) {
      getSubjects({ estudianteId: estudiante, anio, periodoId });
    }
  }, [estudiante, anio, periodoId, getSubjects, isSuccessWithdraw]);

  React.useEffect(() => {
    if (isSuccessWithdraw) {
      toast.success("¡Asignatura retirada correctamente!", toastSuccessStyle);
    }
  }, [isSuccessWithdraw]);

  React.useEffect(() => {
    if (errorWithdraw) {
      toast.error(
        errorWithdraw?.data?.message ?? JSON.parse(errorWithdraw?.data)?.message,
        toastErrorStyle,
      );
    }
  }, [errorWithdraw]);

  const confirm = useCallback(
    (subject: ISubjectByStudent) => {
      modal.confirm({
        title: `¿Está seguro(a) de que desea retirar la asignatura de ${subject?.asignaturaNombre} para el estudiante ${data?.data?.nombreCompleto}`,
        icon: <Icon name="" color="#FFC53D" />,
        okText: "Aceptar",
        onOk: () => {
          withdrawSubject({ seccionId: subject?.seccionId, estudianteId: subject.estudianteId });
        },
        cancelText: "Cancelar",
        style: { top: 200 },
      });
    },
    [modal, withdrawSubject, data],
  );

  const columns = React.useMemo<ColumnsType<ISubjectByStudent>>(
    () => [
      {
        title: "Asignatura",
        dataIndex: "asignaturaNombre",
      },
      {
        title: "Créditos",
        dataIndex: "creditos",
        width: 100,
      },
      {
        title: "Sección",
        dataIndex: "asignaturaSeccionCodigo",
        width: 100,
      },
      {
        title: "Profesor(a)",
        dataIndex: "profesorNombre",
      },
      {
        title: "Horario",
        dataIndex: "horario",
      },
      {
        title: "",
        dataIndex: "asignaturaId",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Button type="default" onClick={() => confirm(record)}>
            Retirar asignatura
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <SectionHeaderStyled>
        <LargeHeadingStyled>
          {data?.estudianteNombre
            ? `Asignaturas inscritas del(la) estudiante ${data?.data?.nombreCompleto} - ${data?.id}`
            : `Retiro de asignaturas administrativo`}
        </LargeHeadingStyled>
      </SectionHeaderStyled>
      <SectionHeaderStyled style={{ gap: 5 }}>
        <Form.Item label="Estudiante (nombre o ID)">
          <Select
            options={studentsOptions}
            filterOption={filterOption}
            placeholder="Búsqueda de estudiantes"
            showSearch
            onSearch={(e) =>
              getStudents({
                rowsCount: 20,
                page: 1,
                estudianteNombre: e,
              })
            }
            optionFilterProp="children"
            allowClear
            loading={isLoadingStudents}
            onChange={(e) => {
              setEstudiante(e);
            }}
          />
        </Form.Item>
        <Form.Item label="Año">
          <Input
            type="number"
            size="small"
            defaultValue={anio}
            onChange={(e) => setAnio(+e.target.value)}
            placeholder="Año"
            style={{ height: "35px", marginRight: "15px" }}
          />
        </Form.Item>
        <Form.Item label="Filtro por periodo">
          <Select
            style={{ minWidth: 200 }}
            options={periodosOptions}
            placeholder="Seleccione un periodo"
            onChange={(e) => {
              setPeriodoId(e);
            }}
            allowClear
          />
        </Form.Item>
      </SectionHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<ISubjectByStudent>
            locale={{
              emptyText: (
                <Box>
                  <Box>
                    <SmallHeadingStyled>
                      {estudiante && anio && periodoId
                        ? "No se encontraron asignaturas inscritas"
                        : "Seleccione un estudiante, año y periodo para mostrar las asignaturas correspondientes"}
                    </SmallHeadingStyled>
                    <Image
                      src={NoResultsSVG}
                      preview={false}
                      alt="Ilustración sin resultados"
                      width={200}
                    />
                  </Box>
                </Box>
              ),
            }}
            loading={isLoading || isLoadingWithdraw}
            columns={columns}
            dataSource={data?.data?.secciones}
            rowKey={(v) => `${v.asignaturaCodigo} ${v.asignaturaNombre}`}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
          />
        </Card>
      </Content>
      {contextHolder}
    </Flex>
  );
};

export default AdminWithdraw;
