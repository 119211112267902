import { Icon } from "@nubeteck/components";
import { Button, Flex, Form, GetRef, Input, InputNumber, InputRef, Modal, Select } from "antd";
import { OptionProps } from "antd/es/select";
import React, { useContext, useEffect, useRef, useState } from "react";

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  record: any;
  defaultValue: number;
  max?: number;
  min?: number;
  fieldType: "options" | "text" | "number" | "grade-feedback";
  handleSave: (record: any) => void;
  options?: OptionProps[] | [];
}

// type EditableTableProps = Parameters<typeof Table>[0];

// type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} key={index} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  children,
  dataIndex,
  editable,
  record,
  max,
  min,
  handleSave,
  fieldType,
  defaultValue,
  options,
  //   ...restProps
}) => {
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const [modalOpen, setModalOpen] = useState(false);
  const [btnFeedbackVisible, setBtnFeedbackVisible] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(null);

  useEffect(() => {
    if (record && record[dataIndex] && record[dataIndex] !== "")
      if (fieldType == "grade-feedback") {
        if (record && record[dataIndex] && record[dataIndex] !== "") {
          form.setFieldsValue({
            [`calificacion${dataIndex}`]: record[dataIndex]?.calificacion,
            [`feedback${dataIndex}`]: record[dataIndex]?.feedback,
          });
          setFeedbackValue(record[dataIndex]?.feedback);
        }
      } else {
        if (record && record[dataIndex] && record[dataIndex] !== "") {
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        } else if (defaultValue) {
          form.setFieldsValue({ [dataIndex]: defaultValue });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const guardarCalificacionFeedback = () => {
    handleSave({
      ...record,
      [dataIndex]: {
        calificacion: form.getFieldValue(`calificacion${dataIndex}`),
        feedback: form.getFieldValue(`feedback${dataIndex}`),
      },
    });
    setModalOpen(false);
  };

  const childNode = children;
  return (
    <>
      {editable ? (
        <td
          onMouseEnter={() => setBtnFeedbackVisible(true)}
          onMouseLeave={() => setBtnFeedbackVisible(false)}
        >
          {fieldType == "grade-feedback" ? (
            <Flex>
              <Form.Item name={`calificacion${dataIndex}`}>
                <InputNumber
                  style={{ width: 70 }}
                  size="small"
                  min={min ?? 0}
                  max={max ?? 100}
                  controls={false}
                  onPressEnter={guardarCalificacionFeedback}
                  onBlur={guardarCalificacionFeedback}
                />
              </Form.Item>
              <div style={{ width: 16 }}>
                {(btnFeedbackVisible || feedbackValue) && (
                  <Button
                    type="text"
                    icon={<Icon name="" outlined color="#A7A7A7" size={17} />}
                    onClick={() => setModalOpen(true)}
                  />
                )}
              </div>
              <Modal
                open={modalOpen}
                closable={false}
                footer={
                  <Flex gap={10}>
                    <Button type="primary" onClick={() => guardarCalificacionFeedback()}>
                      Aceptar
                    </Button>
                    <Button
                      onClick={() => {
                        setModalOpen(false);
                        form.setFieldValue(`feedback${dataIndex}`, record[dataIndex]?.feedback);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Flex>
                }
              >
                <Form.Item name={`feedback${dataIndex}`} label="Retroalimentación">
                  <Input.TextArea ref={inputRef} />
                </Form.Item>
              </Modal>
            </Flex>
          ) : (
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
              {fieldType == "text" ? (
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              ) : fieldType == "number" ? (
                <InputNumber
                  size="small"
                  min={min ?? 0}
                  max={max ?? 100}
                  controls={false}
                  onPressEnter={save}
                  onBlur={save}
                />
              ) : (
                <Select options={options} onBlur={save} />
              )}
            </Form.Item>
          )}
        </td>
      ) : (
        <td>{childNode}</td>
      )}
    </>
  );
};

export const editableComponents = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};
