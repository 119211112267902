import {
  LargeHeadingStyled,
  SectionFiltersHeaderStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Card, Icon } from "@nubeteck/components";
import { Button, Dropdown, Form, Image, Layout, Segmented, Select, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { CourseEmptyCover } from "../../../assets";
import React, { useEffect } from "react";
import {
  useDownloadCoursesMutation,
  useGetCoursesByPeriodMutation,
  useGetTeacherPeriodsMutation,
} from "src/services";
import { NoResultsSVG } from "src/assets";
import { FlexStyled } from "src/components/registration/register-introduction/register-introduction.styled";
import { ITeacherCourse } from "@/Interfaces/teacherCourses";
import { useNavigate } from "react-router-dom";
import { SegmentedValue } from "antd/es/segmented";
import Meta from "antd/es/card/Meta";
import { ImageCardStyled } from "src/components/custom/cards/card.styled";
import { ColumnContainer } from "src/components/layout/main-layout/main-layout.styled";
import { ArrayUtils } from "@nubeteck/utils";
import { downloadExcelBase64 } from "src/utils/files-util";
const { Content } = Layout;

const Courses: React.FC = () => {
  const [getCourses, { data, isLoading }] = useGetCoursesByPeriodMutation();
  const [getPeriods, { data: periodos, isLoadingPeriods }] = useGetTeacherPeriodsMutation();
  const [tipoVista, setTipoVista] = React.useState<SegmentedValue>(1);
  const [periodoId, setPeriodoId] = React.useState(0);
  const [downloadCourses, { isLoading: isLoadingCoursesFile, data: coursesFile }] =
    useDownloadCoursesMutation();

  const navigate = useNavigate();

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);
  useEffect(() => {
    getCourses({ periodoId });
  }, [getCourses, periodoId]);

  useEffect(() => {
    if (coursesFile) {
      downloadExcelBase64(
        `Cursos ${periodos.find((p: any) => p.periodoId === periodoId).periodoNombre}`,
        coursesFile,
      );
    }
  }, [coursesFile, periodoId, periodos]);

  const periodsOptions = ArrayUtils.selectLabelValue(periodos ?? [], "periodoNombre", "periodoId");

  const columns = React.useMemo<ColumnsType<ITeacherCourse>>(
    () => [
      {
        title: "Asignatura",
        dataIndex: "asignaturaNombre",
      },
      {
        dataIndex: "asignaturaSeccionCodigo",
        title: "Sección",
      },
      {
        title: "Período",
        dataIndex: "periodo",
      },
      {
        title: "Duración",
        dataIndex: "duracion",
      },
      {
        title: "Horario",
        dataIndex: "horario",
        // render: (text, record) => {
        //   return record?.horarios?.map((item: string) => `${item.toString()}`).join(", ");
        // },
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            overlayClassName="table-dropdown"
            className="cursor-pointer"
            key={record.seccionId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Ver detalle",
                  icon: <Icon name="" outlined color="#A7A7A7" />,
                  onClick: () => navigate(`${record.seccionId}`),
                },
                {
                  key: "2",
                  label: "Ver en Moodle",
                  icon: <Icon name="" outlined color="#A7A7A7" />,
                  onClick: () => window.open(record.link),
                },
                {
                  key: "3",
                  label: "Registro de calificaciones",
                  icon: <Icon name="" outlined color="#A7A7A7" />,
                  onClick: () => navigate(`course-grades/${record.seccionId}`),
                },
                {
                  key: "4",
                  label: "Reporte de asistencia",
                  icon: <Icon name="" outlined color="#A7A7A7" size={40} />,
                  onClick: () => navigate(`course-attendance/${record.seccionId}`),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm],
  );

  return (
    <ColumnContainer>
      <SectionHeaderStyled>
        <LargeHeadingStyled>Cursos</LargeHeadingStyled>
      </SectionHeaderStyled>
      <SectionFiltersHeaderStyled>
        <FlexStyled style={{ gap: 40 }}>
          <Form.Item label="Período">
            <Select
              loading={isLoadingPeriods}
              allowClear
              value={periodoId}
              options={periodsOptions}
              onChange={(value) => {
                setPeriodoId(value);
              }}
              style={{ minWidth: 200 }}
              placeholder="Seleccione un período"
            />
          </Form.Item>
        </FlexStyled>
        <FlexStyled style={{ gap: 40 }}>
          <Segmented
            onChange={(value) => setTipoVista(value)}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
            options={[
              {
                value: 1,
                icon: (
                  <div style={{ marginTop: 4 }}>
                    <Icon name="" size={17} />
                  </div>
                ),
              },
              {
                value: 2,
                icon: (
                  <div style={{ marginTop: 4 }}>
                    <Icon name="" size={17} />
                  </div>
                ),
              },
            ]}
          />
          <Button
            type="primary"
            onClick={() => downloadCourses({ periodoId })}
            loading={isLoadingCoursesFile}
          >
            Exportar
          </Button>
        </FlexStyled>
      </SectionFiltersHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        {data?.length > 0 ? (
          tipoVista === 1 ? (
            <FlexStyled style={{ justifyContent: "center" }}>
              <FlexStyled
                style={{
                  maxWidth: "1080px",
                  flexWrap: "wrap",
                  gap: "40px",
                }}
              >
                {isLoading ? (
                  <Spin />
                ) : (
                  data?.map((course: ITeacherCourse) => (
                    <ImageCardStyled
                      key={course.seccionId}
                      style={{ width: 240 }}
                      cover={
                        <Button
                          className="image-button"
                          type="text"
                          onClick={() => {
                            navigate(`${course.seccionId}`);
                          }}
                        >
                          <img
                            alt="example"
                            src={course.img && course.img !== "" ? course.img : CourseEmptyCover}
                          />
                        </Button>
                      }
                      actions={[
                        <Button
                          type="link"
                          key="1"
                          onClick={() => navigate(`course-grades/${course.seccionId}`)}
                        >
                          Calificaciones
                        </Button>,
                        <Button
                          type="link"
                          key="2"
                          onClick={() => navigate(`course-attendance/${course.seccionId}`)}
                        >
                          Asistencia
                        </Button>,
                      ]}
                    >
                      <Meta
                        title={`${course.asignaturaSeccionCodigo} - ${course.asignaturaNombre}`}
                        description={
                          <>
                            <div>Período:{course.periodo}</div>
                          </>
                        }
                      />
                      <Button
                        type="link"
                        key="3"
                        onClick={() => (window.location.href = "https://moodle.com/")}
                        style={{ padding: 0 }}
                      >
                        Ver en Moodle
                      </Button>
                    </ImageCardStyled>
                  ))
                )}
              </FlexStyled>
            </FlexStyled>
          ) : (
            <Card bodyStyle={{ padding: 0, width: "100%" }}>
              <Table<ITeacherCourse>
                loading={isLoading}
                columns={columns}
                dataSource={data}
                rowKey={(v) => `${v.asignaturaSeccionCodigo} ${v.asignaturaNombre}`}
                pagination={{
                  defaultPageSize: 5,
                  showSizeChanger: true,
                  style: { marginRight: "30px" },
                }}
                scroll={{ x: 800 }}
              />
            </Card>
          )
        ) : (
          <Card>
            {" "}
            <FlexStyled style={{ flexDirection: "column" }}>
              <SmallHeadingStyled>No se encontraron cursos</SmallHeadingStyled>
              <Image
                src={NoResultsSVG}
                preview={false}
                alt="Ilustración sin resultados"
                width={200}
              />
            </FlexStyled>
          </Card>
        )}
      </Content>
    </ColumnContainer>
  );
};

export default Courses;
